<template>
  <div class="quote-tool-step-content">
    <v-form ref="containerSizeForm">
      <SectionTitle
        id="container-type-header"
        :title="
          `${!containerType ? 'Select Container Size' : containerTypeName}`
        "
      />
      <v-expand-transition>
        <v-radio-group
          v-model="containerType"
          :mandatory="false"
          :rules="$validationRules.required"
          id="container-type-radio-group"
          tabindex="3"
        >
          <v-card
            v-for="(item, i) in containerTypes"
            :key="item.id"
            class="my-1"
            :color="i % 2 ? (userAccess > 0 ? 'grey lighten-3' : '') : ''"
            @click="containerType = item.id"
            @keyup.enter="containerType = item.id"
            @keyup.space="containerType = item.id"
          >
            <v-card-text class="d-flex">
              <v-radio
                class="mb-0 container-type-radio"
                :label="`${item.description}`"
                :value="item.id"
              ></v-radio>
              <v-spacer></v-spacer>
              <span class="align-self-center" v-if="item.dimensions">{{
                item.dimensions
              }}</span>
            </v-card-text>
            <v-card-text class="d-flex justify-space-between pt-0">
              <div class="text px-2">
                <p style="font-size:0.8rem;" class="mb-0 pr-2" v-if="item.hint">
                  {{ item.hint }}
                </p>
              </div>
              <div
                v-if="item.image_url"
                class="image d-flex justify-end align-end"
              >
                <img height="55px" :src="item.image_url" />
              </div>
            </v-card-text>
            <template v-if="showPrices">
              <v-divider class="mb-0"></v-divider>

              <v-card-text class="d-flex justify-end">
                <div class="text px-2 align-self-end">
                  <b>Price Estimate: </b>${{
                    item.price_groups && item.price_groups[0].price
                  }}
                </div>
              </v-card-text>
            </template>
          </v-card>
        </v-radio-group>
      </v-expand-transition>
    </v-form>
    <PublicStepControls
      :canDelete="true"
      :nextStepEnabled="allowNextStep"
      :nextStep="hasStepBeenCompleted(stepObject.component) ? submit : null"
    >
    </PublicStepControls>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapMutations, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { UPDATE_STEP_METADATA } from "@/store/modules/quotes/mutation-types";

import SectionTitle from "./components/SectionTitle.vue";
import PublicStepControls from "../PublicStepControls.vue";
const { mapGetters: mapQuoteGetters } = createNamespacedHelpers("quotes");

/**
 * @typedef {int} isService
 * @typedef {bool} validLocation
 * */
export default {
  name: "ProjectTypeStep",
  props: ["nextStep", "stepObject"],
  components: {
    PublicStepControls,
    SectionTitle
  },
  computed: {
    ...mapFields("quotes", {
      containerType: "quote.service.containerType",
      weightLimit: "quote.service.weightLimit",
      rentalPeriod: "quote.service.rentalPeriod",
      serviceSelectionId: "serviceSelectionId"
    }),
    ...mapFields("quotes", ["isService", "quote", "loading"]),
    ...mapGetters("quotes", [
      "userAccess",
      "validPartialLocation",
      "validLocation",
      "containerTypes",
      "hasStepBeenCompleted"
    ]),
    allowNextStep() {
      return this.valid;
    },
    ...mapQuoteGetters([
      "containerTypes",
      "containerTypes",
      "catalogDetails",
      "terms",
      "containerTypes"
    ]),
    containerTypeName() {
      return (
        this.containerTypes.find(d => d.id === (this.containerType || "")) || {
          description: "N/A"
        }
      ).description;
    }
  },
  methods: {
    ...mapMutations("quotes", [UPDATE_STEP_METADATA]),
    updateStepContinue(val) {
      // Set whether this step can continue or not
      this[UPDATE_STEP_METADATA]({
        component: this.$options.name,
        canContinue: val
      });
    },
    submit() {
      // TODO: Determine why this is not working without a timeout
      // Wait 0.25 seconds
      setTimeout(() => {
        if (this.allowNextStep && this.$refs.containerSizeForm.validate()) {
          this.nextStep();
        }
      }, 250);
    }
  },
  data() {
    return {
      valid: true,
      showPrices: false
    };
  },
  watch: {
    allowNextStep(newVal) {
      // If this step can continue, the the next step should be marked as editable
      this.updateStepContinue(newVal);
    },
    containerType(newVal) {
      // next step
      if (newVal) {
        this.updateStepContinue(true);
        this.submit();
      }
    }
  },
  mounted() {
    // Same as watcher
    this.updateStepContinue(this.allowNextStep);
  }
};
</script>
