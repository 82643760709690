import Vue from "vue";
import * as types from "./mutation-types";
import { getField, updateField } from "vuex-map-fields";

/**
 * To dispatch an action from a Vue component, make sure to prepend you mapper with "baseData"
 * `...mapActions("baseData", ["getCountries"])`
 *
 * To commit mutations from a Vue component
 * `import { SET_STATIC_DATA } from "@/store/modules/baseData/mutation-types"`
 * `...mapMutations("baseData", [SET_STATIC_DATA])`
 */
export default {
  namespaced: true,
  state: {
    loading: false,
    states: {
      items: [],
      error: false,
      message: ""
    },
    debris_types: {
      items: [],
      error: false,
      message: ""
    },
    container_types: {
      items: [],
      error: false,
      message: ""
    },
    haulers: {
      items: [],
      error: false,
      message: ""
    },
    locations: {
      items: [],
      error: false,
      message: ""
    },
    work_order_statuses: {
      items: [],
      error: false,
      message: ""
    },
    work_order_types: {
      items: [],
      error: false,
      message: ""
    },
    routes: {
      items: [],
      error: false,
      message: ""
    },
    users: {
      items: [],
      error: false,
      message: ""
    },
    customer_types: {
      items: [],
      error: false,
      message: ""
    },
    access_levels: {
      items: [],
      error: false,
      message: ""
    },
    service_statuses: {
      items: [],
      error: false,
      message: "",
      filterMeta: {
        quoteTypes: ["Quote Provided", "Quote Requested"]
      }
    },
    facilities: {
      items: [],
      error: false,
      message: ""
    },
    line_item_types: {
      items: [],
      error: false,
      message: "",
      filterMeta: {
        dumpChargeTypes: [
          "Overweight Tons",
          "Mattresses",
          "Couches",
          "Tires",
          "Other"
        ]
      }
    },
    customer_statuses: {
      items: [],
      error: false,
      message: ""
    },
    customer_sources: {
      items: [],
      error: false,
      message: ""
    },
    dump_ticket_statuses: {
      items: [],
      error: false,
      message: ""
    },
    billing_terms: {
      items: [],
      error: false,
      message: ""
    },
    user_info: {
      value: {
        lavel: 0
      },
      error: false,
      message: ""
    },
    override_reasons: {
      items: [],
      error: false,
      message: ""
    }
  },
  getters: {
    getField,
    getById(state, getters) {
      /**
       * Return an item by ID
       * @param {string} getter - The getter you're plucking an item from
       * @param {int} id - The ID of the item you want returned
       * @param {string} idField (optional) - The field used when filtering by ID
       */
      return (getter, id, idField = "id") =>
        getters[getter].find(item => item[idField] === id) || {};
    },
    filterById(state, getters) {
      /**
       * Return items filtered by ID
       * @param {string} getter - The getter you're plucking an item from
       * @param {int} id - The ID of the item you want returned
       * @param {string} idField (optional) - The field used when filtering by ID
       */
      return (getter, id, idField = "id") =>
        getters[getter].filter(item => item[idField] === id) || [];
    },
    // We can provide a shortcut to countries, and return an empty array if it contains an error
    states(state) {
      if (state.states.error) return [];
      return state.states.items;
    },
    debris_types(state) {
      if (state.debris_types.error) return [];
      return state.debris_types.items;
    },
    container_types(state) {
      if (state.container_types.error) return [];
      return state.container_types.items;
    },
    haulers(state) {
      if (state.haulers.error) return [];
      return state.haulers.items;
    },
    locations(state) {
      if (state.locations.error) return [];
      return state.locations.items;
    },
    work_order_statuses(state) {
      if (state.work_order_statuses.error) return [];
      return state.work_order_statuses.items;
    },
    work_order_types(state) {
      if (state.work_order_types.error) return [];
      return state.work_order_types.items;
    },
    routes(state) {
      if (state.routes.error) return [];
      return state.routes.items;
    },
    user_info(state) {
      if (state.user_info.error) return false;
      return state.user_info.value;
    },
    access_levels(state) {
      if (state.access_levels.error) return false;
      return state.access_levels.items;
    },
    service_statuses(state) {
      if (state.service_statuses.error) return false;
      return state.service_statuses.items;
    },
    quote_statuses(state) {
      if (state.service_statuses.error) return false;
      return state.service_statuses.items.filter(item =>
        state.service_statuses.filterMeta.quoteTypes.includes(item.status)
      );
    },
    facilities(state) {
      if (state.facilities.error) return false;
      return state.facilities.items;
    },
    active_facilities(state) {
      if (state.facilities.error) return false;
      return state.facilities.items.filter(item => item.active);
    },
    line_item_types(state) {
      if (state.line_item_types.error) return false;
      return state.line_item_types.items;
    },
    line_item_types_general(state) {
      if (state.line_item_types.error) return false;
      return state.line_item_types.items.filter(
        item =>
          !state.line_item_types.filterMeta.dumpChargeTypes.includes(item.name)
      );
    },
    line_item_types_dump(state) {
      if (state.line_item_types.error) return false;
      return state.line_item_types.items.filter(item =>
        state.line_item_types.filterMeta.dumpChargeTypes.includes(item.name)
      );
    },
    users(state) {
      if (state.users.error) return [];

      // Get the API User
      const apiUser = {
        ...state.users.items.find(item => item.name === "NDD-API"),
        name: "[ Online Order ]"
      };

      // Get all other users
      const users = state.users.items.filter(item => item.name !== "NDD-API");

      // Return the API User and all other sorted users
      return [apiUser, ...users.sort((a, b) => (a.name < b.name ? -1 : 1))];
    },
    sales_users(state) {
      if (state.users.error) return [];

      return state.users.items.filter(
        item => !["Dispatch", "NDD-API"].includes(item.name)
      );
    },
    customer_statuses(state) {
      if (state.customer_statuses.error) return false;
      return state.customer_statuses.items;
    },
    customer_types(state) {
      if (state.customer_types.error) return false;
      return state.customer_types.items;
    },
    dump_ticket_statuses(state) {
      if (state.dump_ticket_statuses.error) return false;
      return state.dump_ticket_statuses.items;
    },
    customer_sources(state) {
      if (state.customer_sources.error) return false;
      return state.customer_sources.items;
    },
    billing_terms(state) {
      if (state.billing_terms.error) return false;
      return state.billing_terms.items;
    },
    override_reasons(state) {
      if (state.override_reasons.error) return false;
      return state.override_reasons.items;
    }
  },
  mutations: {
    updateField,
    [types.SET_STATIC_VALUE](state, { type, data }) {
      // Freeze object, since this data is non-reactive (set and forget)
      state[type].value = Object.freeze(data);
    },
    [types.SET_STATIC_DATA](state, { type, data, key = "items" }) {
      // Freeze object, since this data is non-reactive (set and forget)
      state[type][key] = Object.freeze(data);
    },
    [types.SET_ERROR_DATA](state, { type, message }) {
      // Determine if we're clearing the error, or setting an error
      const hasError = !!message; // If message was provided, hasError will be true
      Vue.set(state[type], "error", hasError);
      Vue.set(state[type], "message", hasError ? message : "");
    },
    setLoading(state, newVal) {
      state.loading = newVal;
    }
  },
  actions: {
    /**
     * Reusable API request method for static data within this module
     *
     * @param commit
     * @param state
     * @param type {string} URL path (/api/{type}). This is also used for the state property, but converts - to _
     * @param force {boolean} force fetching results, even if we already have the data
     * @returns {boolean|Promise<void>}
     */
    getData(
      { commit, state },
      { type, force, key = "items", prefix = "base-data" }
    ) {
      // Keep original type string as URL path
      const url = `${prefix}/${type}`;
      // Make the type string object friendly for use as the state property
      type = type.replaceAll("-", "_");
      // If force was not enabled, and there are no errors
      if (force !== true && state[type].error === false) {
        // If we already have results, return early
        if (state[type].items.length) {
          return true;
        }
      }

      commit("setLoading", true);

      return Vue.axios
        .get(url)
        .then(res => {
          const { data } = res;
          commit(types.SET_ERROR_DATA, { type }); // Clear any errors
          commit(types.SET_STATIC_DATA, { type, data: data.data, key });
        })
        .catch(e => {
          commit(types.SET_ERROR_DATA, { type, message: e });
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    getStates({ dispatch }, force = false) {
      return dispatch("getData", { type: "states", force });
    },
    getDebrisTypes({ dispatch }, force = false) {
      return dispatch("getData", { type: "debris-types", force });
    },
    getContainerTypes({ dispatch }, force = false) {
      return dispatch("getData", { type: "container-types", force });
    },
    getHaulers({ dispatch }, force = false) {
      return dispatch("getData", { type: "haulers", force });
    },
    getLocations({ dispatch }, force = false) {
      return dispatch("getData", { type: "locations", force });
    },
    getWorkOrderStatuses({ dispatch }, force = false) {
      return dispatch("getData", { type: "work-order-statuses", force });
    },
    getWorkOrderTypes({ dispatch }, force = false) {
      return dispatch("getData", { type: "work-order-types", force });
    },
    getUserInfo({ dispatch }, force = false) {
      return dispatch("getData", { type: "user-info", force, key: "value" });
    },
    getUsers({ dispatch }, force = false) {
      return dispatch("getData", {
        type: "users",
        force,
        prefix: ""
      });
    },
    getBulkData({ commit, dispatch }) {
      commit("setLoading", true);

      return Vue.axios
        .get("/base-data/all")
        .then(res => {
          const {
            data: { success, data }
          } = res;

          if (success) {
            Object.keys(data).forEach(key => {
              const storeKey = key.replaceAll("-", "_");
              commit(types.SET_STATIC_DATA, {
                type: storeKey,
                data: data[key],
                key: "items"
              });
            });
          } else {
            dispatch(
              "notify",
              {
                type: "error",
                message: "There was an error fetching base CRM data."
              },
              { root: true }
            );
          }
        })
        .catch(() => {
          // TODO: Do we want to notify the user? This throws when the user isn't authenticated.
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    /**
     * Load base data
     * This gets called from "@/plugins/auth/drivers/jwt.js", which runs when the application starts (including page refreshes)
     *
     * Add `dispatch` items to the Promise.all array for each actions that should get run when the application starts.
     * To run actions outside of this module, see this page:
     * https://vuex.vuejs.org/guide/modules.html#accessing-global-assets-in-namespaced-modules
     *
     * @returns {Promise<unknown[]>}
     */
    load({ dispatch }) {
      return Promise.all([dispatch("getBulkData")]);
    }
  }
};
