<template>
  <div class="quote-tool-step-content">
    <v-form ref="form">
      <v-radio-group
        v-model="projectType"
        :mandatory="false"
        :rules="$validationRules.required"
        id="project-type-radio-group"
        tabindex="2"
      >
        <v-card
          v-for="(item, i) in projectTypes"
          :key="item.id"
          :class="{
            'my-1': $vuetify.breakpoint.smAndDown,
            'my-2': $vuetify.breakpoint.mdAndUp
          }"
          :color="i % 2 ? (userAccess > 0 ? 'grey lighten-3' : '') : ''"
        >
          <v-card-text>
            <v-radio
              class="project-type-radio"
              :label="item.title"
              :value="item.id"
            ></v-radio>
            <small v-if="item.description">{{ item.description }}</small>
          </v-card-text>
        </v-card>
      </v-radio-group>
    </v-form>
    <PublicStepControls
      :canDelete="true"
      :nextStepEnabled="allowNextStep"
      :nextStep="hasStepBeenCompleted(stepObject.component) ? submit : null"
    >
    </PublicStepControls>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapMutations, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { UPDATE_STEP_METADATA } from "@/store/modules/quotes/mutation-types";

import PublicStepControls from "../PublicStepControls.vue";
const { mapGetters: mapQuoteGetters } = createNamespacedHelpers("quotes");

/**
 * @typedef {int} isService
 * @typedef {bool} validLocation
 * */
export default {
  name: "ProjectTypeStep",
  props: ["nextStep", "stepObject"],
  components: {
    PublicStepControls
  },
  computed: {
    ...mapGetters("quotes", ["hasStepBeenCompleted"]),
    ...mapFields("quotes", {
      debrisType: "quote.service.debrisType",
      containerType: "quote.service.containerType",
      projectType: "quote.service.projectType",
      weightLimit: "quote.service.weightLimit",
      rentalPeriod: "quote.service.rentalPeriod",
      serviceSelectionId: "serviceSelectionId"
    }),
    ...mapFields("quotes", ["isService", "quote", "loading"]),
    ...mapGetters("quotes", [
      "userAccess",
      "validPartialLocation",
      "validLocation"
    ]),
    allowNextStep() {
      return this.valid;
    },
    ...mapQuoteGetters([
      "debrisTypes",
      "containerTypes",
      "catalogDetails",
      "terms",
      "projectTypes"
    ]),
    projectTypeName() {
      return (
        this.projectTypes.find(p => p.id === this.projectType) || {
          title: "N/A"
        }
      ).title;
    }
  },
  methods: {
    ...mapMutations("quotes", [UPDATE_STEP_METADATA]),
    updateStepContinue(val) {
      // Set whether this step can continue or not
      this[UPDATE_STEP_METADATA]({
        component: this.$options.name,
        canContinue: val
      });
    },
    submit() {
      console.log(this.allowNextStep, this.$refs.form.validate());
      if (this.allowNextStep && this.$refs.form.validate()) {
        this.nextStep();
      }
    }
  },
  data() {
    return {
      valid: true
    };
  },
  watch: {
    allowNextStep(newVal) {
      // If this step can continue, the the next step should be marked as editable
      this.updateStepContinue(newVal);
    },
    projectType(newVal) {
      // next step
      if (newVal) {
        this.updateStepContinue(true);
        this.submit();
      }
    }
  },
  mounted() {
    // Same as watcher
    this.updateStepContinue(this.allowNextStep);
  }
};
</script>
