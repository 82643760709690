<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-text-field
        id="first-name"
        v-model="firstName"
        label="First Name"
        :rules="$validationRules.required"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        id="last-name"
        v-model="lastName"
        label="Last Name"
        :rules="$validationRules.required"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field
        id="company-name"
        v-model="companyName"
        label="Company Name"
        hint="Optional"
        persistent-hint
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        id="phone-number"
        v-model="phoneNumber"
        label="Phone Number"
        required
        v-mask="$masks.phone"
        :rules="
          purchaseOrder
            ? $validationRules.phone
            : $validationRules.phoneNotRequired
        "
        :persistent-hint="!purchaseOrder"
        :hint="purchaseOrder ? '' : 'Optional'"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        id="email"
        v-model="emailAddress"
        label="Email"
        required
        :rules="$validationRules.email"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapFields } from "vuex-map-fields";

/**
 * @typedef {object} $masks
 * @typedef {object} $validationRules
 * @typedef {string} firstName
 * @typedef {string} lastName
 * @typedef {string} companyName
 * @typedef {string} phoneNumber
 * @typedef {string} emailAddress
 */
export default {
  name: "ClientFields",
  props: {
    purchaseOrder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapFields("quotes", {
      firstName: "quote.customer.firstName",
      lastName: "quote.customer.lastName",
      companyName: "quote.customer.companyName",
      phoneNumber: "quote.customer.phoneNumber",
      emailAddress: "quote.customer.emailAddress"
    })
  }
};
</script>

<style scoped></style>
