<template>
  <v-col cols="12" md="12">
    <v-menu
      v-if="$vuetify.breakpoint.mdAndUp"
      v-model="dateMenu"
      :close-on-content-click="true"
      min-width="auto"
      :nudge-right="30"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          id="preferred-dropoff-date"
          :prepend-inner-icon="prependIcon"
          :value="getFormattedDate('quote.service.preferredDropoffDate')"
          :label="label"
          readonly
          v-bind="attrs"
          v-on="on"
          :rules="
            $validationRules.required.concat([
              v => (v && allowedDates(v) ? true : 'We\'re sorry. Your selected delivery date is not available for the selected service.')
            ])
          "
          :persistent-hint="userAccess === 0"
          :hint="hint"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="preferredDropoffDate"
        @input="dateMenu = false"
        :min="dateRange.today"
        :max="dateRange.sixtyDays"
        show-current
        :no-title="userAccess === 0"
        :allowed-dates="allowedDates"
      ></v-date-picker>
    </v-menu>
    <v-text-field
      v-else
      v-model="preferredDropoffDate"
      :label="label"
      :prepend-inner-icon="prependIcon"
      type="date"
      required
      :min="dateRange.today"
      :max="dateRange.sixtyDays"
      :rules="
        $validationRules.required.concat([
          v => (v && allowedDates(v) ? true : 'We\'re sorry. Your selected delivery date is not available for the selected service.')
        ])
      "
      :persistent-hint="true"
      :hint="hint"
    />
  </v-col>
</template>
<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import { UPDATE_STEP_METADATA } from "@/store/modules/quotes/mutation-types";

/**
 * @typedef {object} $validationRules
 * @typedef {string} preferredDropoffDate
 * @typedef {string} serviceType
 * @typedef {string} pickupDate
 * @typedef {string} workOrderNotes
 * @typedef {string} serviceNotes
 * @typedef {string} siteNotes
 * @typedef {string} poNumber
 * @typedef {string} customerNotes
 * @typedef {number} dre
 * @typedef {function} getFormattedDate
 * @typedef {object} recurring
 */
export default {
  name: "ScheduleDateInput",
  props: {
    hint: {
      type: String,
      default: "You can schedule up to 60 days in the future."
    },
    label: {
      type: String,
      default: "Preferred Delivery Date"
    },
    prependIcon: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState("quotes", ["isService", "currentStep", "baseData"]),
    ...mapGetters("quotes", ["getFormattedDate", "getIsHaulerDateAllowed"]),
    ...mapGetters("baseData", ["work_order_types"]),
    ...mapFields("quotes", {
      preferredDropoffDate: "quote.service.preferredDropoffDate",
      serviceType: "quote.service.service_type",
      dre: "quote.service.rental_period_days",
      pickupDate: "workOrders.final_pickup.service_date",
      pickupStatus: "workOrders.final_pickup.status_id",
      lat: "site.latitude",
      lng: "site.longitude",
      address: "site.formatted_address",
      poNumber: "quote.service.po_number",
      customerNotes: "quote.service.customer_notes",
      serviceId: "quote.service.id",
      scheduledActivities: "recurringWorkOrders",
      workOrders: "workOrders",
      timeRange: "workOrders.recurring.time_range",
      timeWindowStart: "workOrders.recurring.time_window_start",
      timeWindowEnd: "workOrders.recurring.time_window_end",
    }),
    ...mapFields("quotes", ["searchResults"]),
    dateRange() {
      const min = this.$date();
      return {
        today: min.format("YYYY-MM-DD"),
        sixMonths: min.add(6, "month").format("YYYY-MM-DD"),
        sixtyDays: min.add(60, "day").format("YYYY-MM-DD")
      };
    },
    canContinue() {
      return this.valid;
    }
  },
  data() {
    return {
      userAccess: 0,
      valid: false,
      dateMenu: false,
      pickupDateMenu: false,
    };
  },
  methods: {
    ...mapMutations("quotes", [UPDATE_STEP_METADATA]),
    allowedDates(val) {
      let date = this.$date(val);

      return this.getIsHaulerDateAllowed(date);
    }
  }
};
</script>
