import Vue from "vue";
import * as types from "./mutation-types";
import { updateField } from "vuex-map-fields";
import dayjs from "@/plugins/dayjs";
import isEmpty from "lodash.isempty";
import { initData } from "./objects";

const determineOverrideReason = val => {
  if (
    [
      "Price Match",
      "Promotion",
      "Military",
      "Large Volume Job",
      "VIP",
      ""
    ].includes(val)
  ) {
    return val;
  }
  return val ? "Other" : "";
};

const termFields = [
  "overweight_rate",
  "daily_rental_rate",
  "relocate_from_previous_site_rate",
  "relocate_on_site_rate",
  "service_attempt_rate",
  "tires_rate",
  "live_load_wait_rate",
  "mattresses_rate",
  "couches_rate",
  "rental_period_days"
];

const setTerms = (state, payload) => {
  termFields.forEach(field => {
    Vue.set(state.quote.service, field, payload.terms[field]);

    // Original values to preserve
    if (["rental_period_days", "overweight_rate"].includes(field)) {
      Vue.set(state.quote.service, `original_${field}`, payload.terms[field]);
    }
  });
};

const createPriceGroupId = ({
  catalog_id,
  debris_type_id,
  container_type_id
}) => `${catalog_id}-${debris_type_id}-${container_type_id}`;

const priceGroupFormatter = function(priceGroups, catalog_id) {
  if (!priceGroups) return [];
  priceGroups = Object.values(priceGroups).flat();
  return (
    priceGroups
      .map(item => {
        // Deduce pricing information
        const siblings = priceGroups.filter(pgItem => {
          return (
            item.container_type_id === pgItem.container_type_id &&
            item.debris_type_id === pgItem.debris_type_id
          );
        });

        // Use the hauler with a null ID as the base price item
        const baseItem =
          siblings.find(pgItem => !pgItem.hauler_id && pgItem.price) || false;

        // If there isn't a price/base item, discard this item
        if (baseItem === false) return false;

        const hasCurrentCatalog = siblings.filter(
          sItem => sItem.catalog_id === catalog_id
        );

        // If there are no items within the catalog for this debris/container, then discard
        if (!hasCurrentCatalog.length) return false;

        // Set price from base
        item.price = baseItem.price || item.price;
        // Set weight allowance
        item.weight_allowance =
          baseItem.weight_allowance || item.weight_allowance;
        // Set the catalog ID from the base item, since that's how we determine the price
        item.catalog_id = catalog_id;
        // Give each item a unique ID
        item.id = createPriceGroupId(item);

        return item;
      })
      // Remove items that are false
      .filter(item => item !== false)
      // Remove items that don't have a price
      .filter(item => item.price > 0)
      // Set Hauler information
      .reduce((output, item) => {
        const isMatchingPriceGroup = (pg1, pg2) => {
          return (
            pg1.container_type_id === pg2.container_type_id &&
            pg1.debris_type_id === pg2.debris_type_id
          );
        };
        // Skip items that don't have a hauler ID
        if (!(item.hauler_id > 0)) return output;
        // Find other priceGroups of the same type
        let existingIndex = output.findIndex(nItem =>
          isMatchingPriceGroup(nItem, item)
        );
        // If there isn't a item for this container/debris type, add this one
        if (existingIndex === -1) {
          existingIndex = output.push(item) - 1;
        }
        // If .haulers isn't set, then initialize array
        if (
          !output[existingIndex].haulers ||
          !output[existingIndex].haulers.length
        ) {
          output[existingIndex].haulers = [output[existingIndex].hauler_id];
        }
        // If the current hauler_in the array of haulers, then add it
        if (output[existingIndex].haulers.indexOf(item.hauler_id) === -1) {
          output[existingIndex].haulers.push(item.hauler_id);
        }

        return output;
      }, [])
  );
};

export default {
  updateField,
  [types.STEP_INCREMENT](state) {
    state.currentStep++;
  },
  [types.STEP_DECREMENT](state) {
    state.currentStep--;
  },
  [types.SET_STEP](state, payload) {
    state.currentStep = payload;
  },
  [types.UPDATE_STEP_METADATA](state, payload) {
    const { component } = payload;
    const stepExists = state.steps.findIndex(i => i.component === component);
    if (stepExists === -1) {
      state.steps.push({
        component,
        ...payload
      });
    } else {
      Vue.set(
        state.steps,
        stepExists,
        Object.assign({}, state.steps[stepExists], payload)
      );
    }
  },
  [types.SET_QUOTE](state, payload) {
    state.quote = payload;
  },
  [types.UPDATE_QUOTE](state, payload = {}) {
    for (let i in payload) {
      Vue.set(state.quote, i, payload[i]);
    }

    if (payload.customerId) {
      Vue.set(state.quote.customer, "id", payload.customerId);
    }

    if (payload.customerSiteId) {
      Vue.set(state.site, "id", payload.customerSiteId);
    }

    if (payload.serviceId) {
      Vue.set(state.quote.service, "id", payload.serviceId);
    }
  },
  [types.UPDATE_SITE](state, payload = {}) {
    for (let i in payload) {
      Vue.set(state.site, i, payload[i]);
    }

    // Update the payment fields
    if (payload.street_address) {
      Vue.set(state.quote.payment, "street", payload.street_address);
    }

    if (payload.city) {
      Vue.set(state.quote.payment, "city", payload.city);
    }

    if (payload.state) {
      Vue.set(state.quote.payment, "state", payload.state.short_name);
    }

    if (payload.postal_code) {
      Vue.set(
        state.quote.payment,
        "postal_code_display",
        payload.postal_code.code
      );
    }
  },
  [types.UPDATE_CUSTOMER](state, payload = {}) {
    for (let i in payload) {
      Vue.set(state.quote.customer, i, payload[i]);
    }
  },
  [types.UPDATE_SITE_CONTACT](state, payload = {}) {
    for (let i in payload) {
      Vue.set(state.siteContact, i, payload[i]);
    }
  },
  [types.SET_BASE_DATA](state, payload) {
    state.baseData = payload;

    // Public quote tool is only for quotes
    if (payload.userInfo && payload.userInfo.level === 0) {
      state.isService = 0;
    }
  },
  [types.SET_SERVICE_INFO](state, payload) {
    state.serviceInfo = payload;
    // @TODO merge formatted into regular price groups
    state.serviceInfo.priceGroupsFormatted = priceGroupFormatter(
      payload.catalogDetails,
      payload.catalog_id
    );

    // Set term fields
    if (payload.terms) {
      setTerms(state, payload);
    }
  },
  [types.SET_SERVICE_INFO_BY_CITY](state, payload) {
    state.serviceInfoByCity = payload;
    state.serviceInfoByCity.priceGroupsFormatted = priceGroupFormatter(
      payload.catalogDetails,
      payload.catalog_id
    );

    // Set term fields
    if (payload.terms) {
      setTerms(state, payload);
    }
  },
  [types.SET_SERVICE_SELECTION](
    state,
    { debrisType, containerType, serviceHaulers }
  ) {
    state.quote.service.debrisType = debrisType;
    state.quote.service.containerType = containerType;
    state.serviceHaulers = serviceHaulers || [];
  },
  [types.INCREMENT_LOADING](state) {
    state.loading++;
  },
  [types.DECREMENT_LOADING](state) {
    state.loading--;

    if (state.loading < 0) {
      state.loading = 0;
    }
  },
  [types.CLEAR_PLACE_DATA](state) {
    state.serviceInfoByCity = {};
    state.serviceInfo = {};
  },
  [types.SET_SERVICE](state, payload) {
    const priceModifiers = payload.price_modifier_json
      ? JSON.parse(payload.price_modifier_json)
      : [];

    let rentalPeriod = priceModifiers.find(
      item => item.type === "rental_period_days"
    ) || { type: "rental_period_days", id: 0, default: true };

    let weightLimit = priceModifiers.find(
      item => item.type === "weight_allowance"
    ) || { type: "weight_allowance", id: 0, default: true };

    Vue.set(
      state.quote,
      "service",
      Object.assign({}, state.quote.service, {
        // TODO: Verify timezone adjustment
        preferredDropoffDate: payload.preferred_delivery_date
          ? dayjs(payload.preferred_delivery_date)
              .add(5, "hour")
              .format("YYYY-MM-DD")
          : "",
        debrisType: payload.debris_type_id,
        containerType: payload.container_type_id,
        projectType: payload.project_type_id,
        weightLimit: weightLimit.id,
        weightLimitObject: weightLimit,
        rentalPeriod: rentalPeriod.id,
        rentalPeriodObject: rentalPeriod,
        hauler: payload.hauler_id,
        override_price: payload.override_price,
        override_description:
          determineOverrideReason(payload.override_description) === "Other"
            ? payload.override_description
            : "",
        override_reason: determineOverrideReason(payload.override_description),
        weight_allowance: payload.weight_allowance,
        delivery_time: "any",
        email_comments: payload.email_comments,
        notes: payload.notes,
        manage_services_quote: payload.manage_services_quote,
        rental_period_days: payload.rental_period_days,
        id: payload.id,
        referral_notes: payload.referral_notes,
        customer_notes: payload.customer_notes,
        customer_id: payload.customer_id,
        service_status: payload.service_status,
        price_modifier_json: payload.price_modifier_json
      })
    );

    [
      // Additional service fields that are a 1-1 map
      "daily_rental_rate",
      "overweight_rate",
      "override_exchange_price",
      "mattresses_rate",
      "couches_rate",
      "tires_rate",
      "live_load_wait_rate",
      "relocate_from_previous_site_rate",
      "relocate_on_site_rate",
      "service_attempt_rate"
    ].map(item => {
      Vue.set(state.quote.service, item, payload[item]);
    });

    Vue.set(state, "selectedHauler", payload.hauler_id);
    Vue.set(
      state,
      "isService",
      !payload.is_quote || payload.service_status.status === "Quote Provided"
        ? 1
        : 0
    );
    Vue.set(state, "serviceSelectionId", createPriceGroupId(payload));

    const setWorkOrder = (workOrder, type) => {
      if (!isEmpty(workOrder)) {
        const keep = [
          "id",
          "service_date",
          "time_range",
          "type_id",
          "time_window_start",
          "time_window_end",
          "status_id"
        ];

        const filteredWorkOrder = Object.keys(workOrder)
          .filter(key => keep.includes(key))
          .reduce((obj, key) => {
            obj[key] = workOrder[key];
            return obj;
          }, {});

        // Remap the notes field
        if (workOrder.work_order_note) {
          filteredWorkOrder.notes = workOrder.work_order_note;
        }

        Object.keys(workOrder).forEach(key => {
          Vue.set(state.workOrders[type], key, workOrder[key]);
        });

        // Format the date, if it's set
        if (workOrder.service_date) {
          Vue.set(
            state.workOrders[type],
            "service_date",
            // TODO: adjust for timezone
            dayjs(workOrder.service_date)
              .add(5, "hour")
              .format("YYYY-MM-DD")
          );
        }
      }
    };

    setWorkOrder({ service_date: payload.preferred_delivery_date }, "delivery");

    if (payload.work_orders.length) {
      setWorkOrder(
        payload.work_orders.find(
          workOrder =>
            workOrder.work_order_type.dispatch_identifier === "delivery"
        ) || {},
        "delivery"
      );
      setWorkOrder(
        payload.work_orders.find(
          workOrder =>
            workOrder.work_order_type.dispatch_identifier === "final-pickup"
        ) || {},
        "final_pickup"
      );
    }
  },
  [types.RESET_ALL_DATA](state) {
    const baseData = initData();

    state.currentStep = baseData.currentStep;
    state.loading = baseData.loading;
    state.messages = baseData.messages;
    state.quote = baseData.quote;
    state.recurringWorkOrders = baseData.recurringWorkOrders;
    state.redirectUrl = baseData.redirectUrl;
    state.searchResults = baseData.searchResults;
    state.selectedHauler = baseData.selectedHauler;
    state.sendQuote = baseData.sendQuote;
    state.serviceInfo = baseData.serviceInfo;
    state.serviceInfoByCity = baseData.serviceInfoByCity;
    state.serviceInfoOverrides = baseData.serviceInfoOverrides;
    state.serviceSelectionId = baseData.serviceSelectionId;
    state.site = baseData.site;
    state.siteContact = baseData.siteContact;
    state.siteContacts = baseData.siteContacts;
    state.steps = baseData.steps;
    state.workOrders = initData().workOrders;
    state.baseData = baseData.baseData;
  },
  [types.SET_SITE_CONTACTS](state, siteContacts) {
    Vue.set(
      state,
      "siteContacts",
      siteContacts && Array.isArray(siteContacts) ? siteContacts : []
    );
  },
  [types.SET_SITE](state, site) {
    Vue.set(state, "site", {
      id: !site.pending_review ? site.id : 0,
      pending_id: state.site.pending_id || (site.pending_review ? site.id : 0),
      formatted_address: site.formatted_address,
      street_number: site.street_number,
      street: site.street_name ?? site.street,
      city: site.city,
      state: {
        id: site.state_id,
        name: site.long_state || site.state,
        short_name: site.short_state
      },
      postal_code: {
        id: site.postal_code_id,
        code: site.postal_code
      },
      latitude: parseFloat(site.latitude),
      longitude: parseFloat(site.longitude),
      notes: site.notes,
      accuracy: site.accuracy,
      site_title: site.site_title
    });

    if (site.site_contact_id) {
      Vue.set(state.siteContact, "id", site.site_contact_id);
    }

    // Set the default info for payment
    Vue.set(state.quote.payment, "street", site.street);
    Vue.set(state.quote.payment, "city", site.city);
    Vue.set(state.quote.payment, "state", site.state.short_name);
    Vue.set(state.quote.payment, "postal_code_display", site.postal_code.code);
  },
  [types.SET_CUSTOMER](
    state,
    {
      id,
      customer_type,
      company_name,
      first_name,
      last_name,
      phone,
      phone_extension,
      email,
      street,
      city,
      state: customer_state,
      short_state,
      state_id,
      postal_code,
      secondary_info,
      charge_at_sale,
      notes,
      is_tax_exempt,
      balance,
      customer_contacts,
      customer_type_id,
      customer_status_id,
      pending_review,
      customer_source_id,
      final_pickup_billing,
      billing_term_id
    }
  ) {
    Vue.set(state.quote, "customer", {
      customer_type,
      firstName: first_name,
      lastName: last_name,
      companyName: company_name,
      phone_extension: phone_extension,
      emailAddress: email,
      phoneNumber: phone,
      street,
      city,
      postal_code_display: postal_code,
      postal_code: {
        id: "",
        code: postal_code
      },
      state: {
        id: state_id,
        name: customer_state,
        short_name: short_state
      },
      secondary_info,
      charge_at_sale,
      notes,
      is_tax_exempt,
      balance,
      customer_type_id,
      customer_status_id,
      customer_source_id,
      pending_id: state.quote.customer.pending_id || 0,
      final_pickup_billing,
      billing_term_id
    });

    if (customer_contacts) {
      Vue.set(
        state,
        "siteContacts",
        Array.isArray(customer_contacts)
          ? customer_contacts.map(contact => {
              if (contact.pending_review) {
                contact.pending_id = contact.id;
                contact.id = 0;
              }
              return contact;
            })
          : []
      );

      let primaryContact =
        customer_contacts.find(cc => cc.is_primary === true) || {};

      if (primaryContact.value && !state.siteContact.id) {
        Vue.set(state.siteContact, "id", primaryContact.value);
      }
    }

    if (id && !pending_review) {
      Vue.set(state.quote.customer, "id", id);
    } else if (id && pending_review) {
      Vue.set(state.quote.customer, "pending_id", id);
    }
  },
  [types.SET_SITE_CONTACT](
    state,
    {
      id,
      first_name,
      last_name,
      phone,
      phone_extension,
      phone2,
      phone2_extension,
      email,
      pending_review,
      is_billing,
      is_primary
    }
  ) {
    Vue.set(state, "siteContact", {
      id: !pending_review ? id : 0 || 0,
      pending_id: state.siteContact.pending_id || (pending_review ? id : 0),
      first_name,
      last_name,
      phone,
      phone_extension,
      phone2,
      phone2_extension,
      email,
      is_billing,
      is_primary
    });
  },
  [types.SET_RESULTS_GEOLOCATION](state, payload) {
    state.searchResults.geolocation = payload;
  },
  [types.SET_MESSAGE](state, { type, message }) {
    state.messages[type] = message;
  },
  [types.UPDATE_REDIRECT_URL](state, url) {
    state.redirectUrl = url;
  },
  [types.RESET_STATE](state) {
    Object.assign(state, {
      ...initData(),
      baseData: {
        ...state.baseData
      }
    });
  },
  [types.RESET_PRICING](state) {
    if (!state.quote.service.id && !state.quote.service.pending_id) {
      Object.assign(
        state.quote.service,
        initData().quote.service,
        {
          debrisType: state.quote.service.debrisType,
          containerType: state.quote.service.containerType
        },
        termFields.reduce((a, b) => {
          a[b] = state.serviceInfo.terms[b];

          return a;
        }, {})
      );
    }
  },
  [types.SET_RESULTS_QUOTE_DUPES](state, payload) {
    for (let key in payload) {
      Vue.set(state.searchResults.quotes, key, payload[key]);
    }
  },
  [types.SET_RESULTS_CUSTOMER_DUPES](state, payload) {
    for (let key in payload) {
      Vue.set(state.searchResults.customer, key, payload[key]);
    }
  },
  SET_CONFIG(state, payload) {
    state.currentConfig = { ...payload };
  },
  [types.CLONE_CURRENT_STATE_TO_LOCAL_STORAGE](state) {
    const clone = JSON.parse(JSON.stringify(state));
    localStorage.setItem("vuex", JSON.stringify(clone));
  },
  [types.RESTORE_LOCAL_STORAGE_TO_CURRENT_STATE](state) {
    const localState = localStorage.getItem("vuex");
    if (localState) {
      const parsed = JSON.parse(localState);
      Object.assign(state, parsed);
    }
  },
  [types.SET_CURRENT_STEP](state, payload) {
    state.currentStep = payload;
  }
};
