export const isNumeric = val => {
  if (typeof val === "number") return true;
  if (typeof val != "string") return false; // we only process strings!
  return !isNaN(val) && !isNaN(parseFloat(val));
};

export const isValidId = val => {
  if (typeof val == "number") return val > 0;
  return isNumeric(val);
};

export const getAddressComponent = (componentName, address, short = false) => {
  if (!address) return;

  let component = false;

  (Array.isArray(componentName) ? componentName : [componentName]).forEach(
    name => {
      if (!component) {
        component = address.find(v => v.types && v.types.includes(name));
      }
    }
  );

  return component
    ? short
      ? component.short_name
      : component.long_name
    : null;
};

export const getAddressComponents = (componentNames, address) => {
  const result = {};
  componentNames.forEach(component => {
    result[component.name] = getAddressComponent(
      component.value,
      address,
      !!component.short
    );
  });

  return result;
};

// Recursive function to find parent function and validate the form
export const parentValidate = component => {
  if (component !== undefined) {
    return component.$refs.form
      ? component.$refs.form.validate()
      : parentValidate(component.$parent);
  }
};

export const getBaseUrl = () => {
  return (process.env.VUE_APP_API_URL || "").replace(/\/$/, "");
};

export const getFullUrl = (path, queryParams = {}) => {
  let query = Object.entries(queryParams)
    .map(e => e.join("="))
    .join("&");
  return `${getBaseUrl()}/${path}${query.length ? "?" + query : ""}`;
};

export const pluralize = (unit, count) =>
  count === 0 || count > 1 ? `${unit}s` : unit;
