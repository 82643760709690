<template>
  <div class="quote-tool-step-content">
    <v-row>
      <v-col v-if="publicRedirect">
        <v-alert icon="mdi-link" prominent text type="info">
          Hang tight...
          <v-progress-linear color="primary" indeterminate></v-progress-linear>
        </v-alert>
      </v-col>
      <v-col v-else>
        <v-alert v-if="quote.purchaseService" type="success">
          <template v-if="loading">
            Please wait while we process your order...
          </template>
          <template v-else>
            Thank you for placing your order! You will receive an email shortly
            with the details of your order.
          </template>
        </v-alert>
        <v-alert type="success" v-else>
          <template v-if="loading">
            Please wait while we process your request...
          </template>
          <template v-else>
            Thank you for your request! You will receive an email shortly with
            the details of your quote.
          </template>
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "FinishStep",
  props: ["nextStep", "previousStep"],
  computed: {
    ...mapState("quotes", [
      "loading",
      "redirectUrl",
      "isService",
      "preventRedirects",
      "currentStep",
      "quote"
    ]),
    ...mapGetters("quotes", ["userAccess"]),
    publicRedirect() {
      return this.userAccess === 0 &&
        this.currentStep === 4 &&
        !this.loading &&
        (window.location.href.includes("nextdaystaging") ||
          window.location.href.includes("nextdaydumpsters"))
        ? "/thank-you-request-a-quote"
        : false;
    }
  },
  watch: {
    redirectUrl(val) {
      if (val && !this.preventRedirects) {
        this.$router.push(val);
      }
    },
    publicRedirect(val) {
      if (val) {
        window.location.href = val;
      }
    }
  },
  mounted() {
    if (this.publicRedirect) {
      window.location.href = this.publicRedirect;
    }
  },
  data() {
    return {};
  }
};
</script>
