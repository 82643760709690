export const STEP_INCREMENT = "STEP_INCREMENT";
export const STEP_DECREMENT = "STEP_DECREMENT";
export const SET_STEP = "SET_STEP";
export const SET_QUOTE = "SET_QUOTE";
export const UPDATE_QUOTE = "UPDATE_QUOTE";
export const SET_BASE_DATA = "SET_BASE_DATA";
export const SET_SERVICE_INFO = "SET_SERVICE_INFO";
export const SET_SERVICE_INFO_BY_CITY = "SET_SERVICE_INFO_BY_CITY";
export const INCREMENT_LOADING = "INCREMENT_LOADING";
export const DECREMENT_LOADING = "DECREMENT_LOADING";
export const SET_SERVICE_SELECTION = "SET_SERVICE_SELECTION";
export const SET_SERVICE = "SET_SERVICE";
export const CLEAR_PLACE_DATA = "CLEAR_PLACE_DATA";
export const SET_RESULTS_GEOLOCATION = "SET_RESULTS_GEOLOCATION";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_SITE_CONTACT = "SET_SITE_CONTACT";
export const SET_SITE = "SET_SITE";
export const SET_SITE_CONTACTS = "SET_SITE_CONTACTS";
export const SET_MESSAGE = "SET_MESSAGE";
export const UPDATE_SITE = "UPDATE_SITE";
export const UPDATE_REDIRECT_URL = "UPDATE_REDIRECT_URL";
export const UPDATE_STEP_METADATA = "UPDATE_STEP_METADATA";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_SITE_CONTACT = "UPDATE_SITE_CONTACT";
export const RESET_STATE = "RESET_STATE";
export const RESET_PRICING = "RESET_PRICING";
export const SET_RESULTS_QUOTE_DUPES = "SET_RESULTS_QUOTE_DUPES";
export const SET_RESULTS_CUSTOMER_DUPES = "SET_RESULTS_CUSTOMER_DUPES";
export const RESET_ALL_DATA = "RESET_ALL_DATA";

export const CLONE_CURRENT_STATE_TO_LOCAL_STORAGE =
  "CLONE_CURRENT_STATE_TO_LOCAL_STORAGE";

export const RESTORE_LOCAL_STORAGE_TO_CURRENT_STATE =
  "RESTORE_LOCAL_STORAGE_TO_CURRENT_STATE";

export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
