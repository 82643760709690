import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#f78e1e",
        secondary: "#464646",
        accent: "#F58220",
        error: "#FF5252",
        info: "#2196F3",
        success: "#f78e1e",
        warning: "#FFC107",
        action: "#f78e1e",
        brand: "#474C55"
      },
      dark: {
        primary: "#f78e1e",
        secondary: "#464646",
        accent: "#727373",
        error: "#FF5252",
        info: "#2196F3",
        success: "#f78e1e",
        warning: "#FFC107",
        action: "#f78e1e",
        disabled: "#f79e1e",
        brand: "#0f4478"
      }
    }
  }
});
