var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quote-tool-step-content"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[[_c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-menu',{attrs:{"close-on-content-click":true,"min-width":"auto","nudge-right":30,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"id":"preferred-dropoff-date","value":_vm.getFormattedDate('quote.service.preferredDropoffDate'),"label":"Preferred Drop-Off Date (max 60 days in the future)","prepend-icon":"mdi-calendar","readonly":"","rules":_vm.$validationRules.required.concat([
                    function (v) { return v && _vm.allowedDates(v)
                        ? true
                        : 'This date is not allowed.'; }
                  ]),"persistent-hint":_vm.userAccess === 0,"hint":_vm.userAccess === 0
                    ? 'Your preferred date is subject to availability. Should you decide to place an order, we will contact you if your preferred date is unavailable.'
                    : ''}},'v-text-field',attrs,false),on))]}}],null,false,154147293),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.dateRange.today,"max":_vm.dateRange.sixtyDays,"show-current":"","no-title":_vm.userAccess === 0,"allowed-dates":_vm.allowedDates,"events":_vm.getEvent},on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.preferredDropoffDate),callback:function ($$v) {_vm.preferredDropoffDate=$$v},expression:"preferredDropoffDate"}})],1):_c('v-text-field',{attrs:{"prepend-icon":"mdi-calendar","label":"Preferred Dropoff Date (max 60 days in the future)","type":"date","required":"","min":_vm.dateRange.today,"max":_vm.dateRange.sixtyDays,"rules":_vm.$validationRules.required.concat([
                function (v) { return v && _vm.allowedDates(v) ? true : 'This date is not allowed.'; }
              ]),"persistent-hint":_vm.userAccess === 0,"hint":_vm.userAccess === 0
                ? 'Your preferred date is subject to availability. Should you decide to place an order, we will contact you if your preferred date is unavailable.'
                : ''},model:{value:(_vm.preferredDropoffDate),callback:function ($$v) {_vm.preferredDropoffDate=$$v},expression:"preferredDropoffDate"}}),(_vm.userAccess > 0 && _vm.getEvent(_vm.preferredDropoffDate))?_c('v-alert',{attrs:{"outlined":"","dense":"","type":"error"}},[_vm._v("The date selected would not be allowed based on the haulers date rules.")]):_vm._e()],1),_c('v-col',[(_vm.userAccess === 0)?_c('v-textarea',{attrs:{"id":"quote-request-notes","prepend-icon":"mdi-note-text","label":"Additional Comments & Questions"},model:{value:(_vm.customerNotes),callback:function ($$v) {_vm.customerNotes=$$v},expression:"customerNotes"}}):_c('div',{staticClass:"mt-15",staticStyle:{"min-height":"100px"}})],1)]],2)],1),_c('StepControls',{attrs:{"next-step-enabled":_vm.valid,"nextStep":_vm.submit,"previousStep":_vm.previousStep}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }