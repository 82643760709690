<template>
  <div class="quote-tool-step-content">
    <v-row v-if="quoteError">
      <v-col>
        <v-alert color="warning">{{ quoteError }}</v-alert>
      </v-col>
    </v-row>
    <v-form v-model="valid" ref="form">
      <CustomerForm />
    </v-form>
    <PublicStepControls
      :nextStepEnabled="canContinue"
      :nextStep="submit"
      :nextStepTitle="nextStepTitle"
    >
    </PublicStepControls>
  </div>
</template>
<script>
import { isValidId } from "@/utils";
import { mapGetters, mapState, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import CustomerForm from "./components/customer/CustomerForm";
import { UPDATE_STEP_METADATA } from "@/store/modules/quotes/mutation-types";
import PublicStepControls from "../PublicStepControls.vue";

/**
 * @typedef {object} $validationRules
 * @typedef {string} firstName
 * @typedef {string} lastName
 * @typedef {string} companyName
 * @typedef {string} phoneNumber
 * @typedef {string} emailAddress
 * @typedef {boolean} useForm
 * @typedef {boolean} hideForm
 * @typedef {boolean} doneDupeChecking
 */
export default {
  name: "CustomerStep",
  props: ["nextStep", "previousStep", "stepKey"],
  components: {
    PublicStepControls,
    CustomerForm
  },
  computed: {
    nextStepTitle() {
      return this.userAccess === 0 && this.stepKey > 5
        ? "Email My Quote"
        : this.siteSearchResults.length &&
          this.shouldSiteSearch &&
          !this.quote.customer.id
        ? "None Of These Customers"
        : "Continue";
    },
    ...mapFields("quotes", {
      firstName: "quote.customer.firstName",
      lastName: "quote.customer.lastName",
      companyName: "quote.customer.companyName",
      phoneNumber: "quote.customer.phoneNumber",
      emailAddress: "quote.customer.emailAddress",
      doneDupeChecking: "searchResults.customer.doneDupeChecking",
      useForm: "searchResults.customer.useForm",
      hideForm: "searchResults.customer.hideForm",
      siteSearchResults: "searchResults.geolocation.sites"
    }),
    ...mapGetters("quotes", ["quoteError", "userAccess"]),
    ...mapState("quotes", ["isService", "currentStep", "quote"]),
    shouldSiteSearch() {
      return (
        this.userAccess > 0 &&
        !this.useForm &&
        this.stepKey === this.currentStep &&
        this.quote.service.service_status.status !== "Quote Requested"
      );
    },
    canDupeCheck() {
      return (
        this.userAccess > 0 &&
        this.useForm &&
        !this.doneDupeChecking &&
        this.quote.service.manage_services_quote !== 1 &&
        !isValidId(this.quote.customer.id) // Don't dupe check if this is an existing customer
      );
    },
    canContinue() {
      // If valid is false, continue should be disabled
      if (!this.valid) return false;
      // If user is a 0, they can continue
      if (this.userAccess === 0) return true;
      // If shouldSiteSearch is true, they can continue
      if (this.shouldSiteSearch) return true;
      // If this is a service but we're missing a phone number, they cannot continue
      if (this.isService && !this.quote.customer.phoneNumber) return false;
      // If an existing site search result was chosen, the user can continue
      if (!this.useForm && this.quote.customer.id) return true;
      // If user is filling out the form (or looking at duplicates list), they can continue
      if (this.useForm) return true;

      return false;
    },
    shouldCancelSiteSearch() {
      return !this.useForm && this.shouldSiteSearch && !this.quote.customer.id;
    }
  },
  methods: {
    ...mapMutations("quotes", [UPDATE_STEP_METADATA]),
    setCanContinue(newVal) {
      this[UPDATE_STEP_METADATA]({
        component: this.$options.name,
        canContinue: newVal
      });
    },
    dupeCheckContinue() {
      this.doneDupeChecking = true;
      this.submit();
    },
    setUseForm() {
      this.useForm = true;
    },
    cancelSiteSearch() {
      this.useForm = true;
      this.hideForm = false;
    },
    submit() {
      Promise.resolve(this.$refs.form.validate()).then(valid => {
        if (this.shouldCancelSiteSearch) {
          this.cancelSiteSearch();
        } else {
          if (valid) {
            if (this.canDupeCheck) {
              if (!this.hideForm && !this.doneDupeChecking) {
                this.hideForm = true;
                return true;
              } else {
                this.hideForm = false;
                this.doneDupeChecking = true;
                this.nextStep();
              }
            } else {
              this.nextStep();
            }
          }
        }
      });
    }
  },
  watch: {
    canContinue(newVal) {
      this.setCanContinue(newVal);
    },
    currentStep(newVal) {
      if (newVal === 3) {
        if (!this.firstName && !this.companyName) {
          this.doneDupeChecking = false;
          this.useForm = false;
        }

        if (this.doneDupeChecking) {
          this.hideForm = false;
        }

        if (this.quote.service.service_status.status === "Quote Requested") {
          this.useForm = true;
        }
      }
    }
  },
  data() {
    return {
      valid: false
    };
  },
  mounted() {
    this.setCanContinue(this.canContinue);
  }
};
</script>
