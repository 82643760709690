<template>
  <div>
    <v-toolbar-title class="title pb-2 d-flex">
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn v-if="showEdit" @click="$emit('edit', true)" text>Edit</v-btn>
    </v-toolbar-title>
    <v-toolbar-title v-if="subtitle" class="subtitle-1 text-wrap pb-2">
      <slot>{{ subtitle }}</slot>
    </v-toolbar-title>
    <v-toolbar-title
      v-if="$slots['subtitle']"
      class="subtitle-1 pb-2 text-wrap"
    >
      <slot name="subtitle"></slot>
    </v-toolbar-title>

    <v-divider v-if="!noDivider" class="mb-2"></v-divider>
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
  props: ["title", "showEdit", "subtitle", "noDivider"],
  data() {
    return {};
  }
};
</script>
