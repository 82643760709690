var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-menu',{attrs:{"close-on-content-click":true,"min-width":"auto","nudge-right":30,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"id":"preferred-dropoff-date","prepend-inner-icon":_vm.prependIcon,"value":_vm.getFormattedDate('quote.service.preferredDropoffDate'),"label":_vm.label,"readonly":"","rules":_vm.$validationRules.required.concat([
            function (v) { return (v && _vm.allowedDates(v) ? true : 'We\'re sorry. Your selected delivery date is not available for the selected service.'); }
          ]),"persistent-hint":_vm.userAccess === 0,"hint":_vm.hint}},'v-text-field',attrs,false),on))]}}],null,false,4036053965),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.dateRange.today,"max":_vm.dateRange.sixtyDays,"show-current":"","no-title":_vm.userAccess === 0,"allowed-dates":_vm.allowedDates},on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.preferredDropoffDate),callback:function ($$v) {_vm.preferredDropoffDate=$$v},expression:"preferredDropoffDate"}})],1):_c('v-text-field',{attrs:{"label":_vm.label,"prepend-inner-icon":_vm.prependIcon,"type":"date","required":"","min":_vm.dateRange.today,"max":_vm.dateRange.sixtyDays,"rules":_vm.$validationRules.required.concat([
        function (v) { return (v && _vm.allowedDates(v) ? true : 'We\'re sorry. Your selected delivery date is not available for the selected service.'); }
      ]),"persistent-hint":true,"hint":_vm.hint},model:{value:(_vm.preferredDropoffDate),callback:function ($$v) {_vm.preferredDropoffDate=$$v},expression:"preferredDropoffDate"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }