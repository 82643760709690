import {
  SET_BASE_DATA,
  SET_QUOTE,
  UPDATE_QUOTE,
  SET_SERVICE_INFO,
  SET_SERVICE_INFO_BY_CITY,
  STEP_DECREMENT,
  INCREMENT_LOADING,
  DECREMENT_LOADING,
  SET_SERVICE_SELECTION,
  SET_SERVICE,
  CLEAR_PLACE_DATA,
  SET_RESULTS_GEOLOCATION,
  SET_SITE_CONTACT,
  SET_MESSAGE,
  UPDATE_SITE,
  SET_SITE,
  SET_CUSTOMER,
  UPDATE_REDIRECT_URL,
  UPDATE_CUSTOMER,
  SET_RESULTS_QUOTE_DUPES,
  SET_RESULTS_CUSTOMER_DUPES,
  RESET_ALL_DATA,
  SET_CURRENT_STEP
} from "./mutation-types";

import Vue from "vue";

import { getAddressComponents, getAddressComponent } from "@/utils";

export default {
  setQuote: ({ commit, state }, newValue) => {
    commit(SET_QUOTE, newValue);
    return state.quote;
  },
  siteSearch({ commit, getters }) {
    if (!getters.geoLocation.lat || !getters.geoLocation.lng) return;
    return Vue.axios
      .get(
        `/search/geocode?latitude=${getters.geoLocation.lat}&longitude=${getters.geoLocation.lng}`
      )
      .then(res => {
        let { data } = res;
        if (!data) data = { success: false, sites: [] };
        commit(SET_RESULTS_GEOLOCATION, {
          success: data.success,
          sites: data.sites
        });
      })
      .catch(e =>
        commit(SET_RESULTS_GEOLOCATION, {
          success: false,
          message: e,
          sites: []
        })
      );
  },
  quoteDupeCheck({ commit, getters, state }) {
    if (!getters.geoLocation.lat || !getters.geoLocation.lng) return;
    return Vue.axios
      .post(`/quotes/quote-dupe-check`, {
        lat: getters.geoLocation.lat,
        lng: getters.geoLocation.lng,
        debris_type_id: state.quote.service.debrisType,
        container_type_id: state.quote.service.containerType
      })
      .then(res => {
        let { data } = res;
        if (!data)
          data = {
            success: false,
            exact_matches: [],
            partial_matches: []
          };

        commit(SET_RESULTS_QUOTE_DUPES, {
          success: data.success,
          exact_matches: data.exact_matches,
          partial_matches: data.partial_matches,
          doneDupeChecking:
            (!data.exact_matches.length && !data.partial_matches.length) ||
            state.searchResults.quotes.doneDupeChecking
        });
      })
      .catch(e =>
        commit(SET_RESULTS_QUOTE_DUPES, {
          success: false,
          message: e,
          exact_matches: [],
          partial_matches: [],
          doneDupeChecking: true
        })
      );
  },
  loadCustomer(commit, { customer_id, site_id }) {
    return Vue.axios.get(
      `customer/${customer_id}${site_id ? "/" + site_id : ""}?minimal=true`
    );
  },
  async searchDupes({ commit }, fields) {
    const params = Object.entries(fields)
      .map(e => e.join("="))
      .join("&");

    // Reset the results
    commit(SET_RESULTS_CUSTOMER_DUPES, { matches: [], success: false });

    // Check for dupes
    const {
      data: { customers: matches, success }
    } = await Vue.axios.get(`search/dupes?${params}`);
    if (matches) {
      commit(SET_RESULTS_CUSTOMER_DUPES, { matches, success });
    }

    return matches;
  },
  processQuotePayment: ({ state, getters, commit }) => {
    commit(INCREMENT_LOADING);

    return Vue.axios
      .post("quotes/pay", {
        ...getters.paymentData,
        service_id: state.quote.serviceId
      })
      .then(response => {
        const { data } = response;
        if (data.success) {
          commit(UPDATE_QUOTE, {
            paid: true,
            error: false,
            paymentResult: data
          });
        } else if (data.success === false) {
          commit(UPDATE_QUOTE, {
            error: data.error ? data.error : "Unknown Error",
            errors: data.errors ? data.errors : {}
          });
          commit(STEP_DECREMENT);
        }
        commit(DECREMENT_LOADING);
      })
      .catch(() => {
        commit(STEP_DECREMENT);
        commit(UPDATE_QUOTE, {
          error: "There was an issue processing your payment, please try again."
        });
        commit(DECREMENT_LOADING);
      });
  },
  createQuote: ({ getters, commit, dispatch }, skipQuote = false) => {
    commit(INCREMENT_LOADING);
    return Vue.axios
      .post(
        "quotes/create-quote",
        skipQuote
          ? {
              ...getters.payload,
              bypass_quote_email: true
            }
          : getters.payload
      )
      .then(response => {
        const { data } = response;
        if (data.success) {
          // Refresh the live data
          dispatch("live/refresh", null, { root: true });

          commit(UPDATE_QUOTE, {
            created: true,
            error: false,
            serviceId: data.serviceId,
            customerId: data.customerId,
            customerSiteId: data.customerSiteId
          });

          if (data.redirectUrl) {
            commit(UPDATE_REDIRECT_URL, data.redirectUrl);
          }
        } else if (data.success === false) {
          commit(UPDATE_QUOTE, {
            error: data.error ? data.error : "Unknown Error",
            errors: data.errors ? data.errors : {}
          });
          commit(STEP_DECREMENT);
        }
        commit(DECREMENT_LOADING);

        return data;
      })
      .catch(() => {
        commit(STEP_DECREMENT);
        commit(UPDATE_QUOTE, {
          error: "There was an issue submitting your quote, please try again."
        });
        commit(DECREMENT_LOADING);

        return { success: false };
      });
  },
  loadService: ({ commit, dispatch }, serviceId) => {
    commit(INCREMENT_LOADING);
    return Vue.axios.get(`quotes/load-service/${serviceId}`).then(response => {
      dispatch("loadCustomerSite", {
        customer_id: response.data.service.customer_id,
        site_id: response.data.service.customer_site_id
      }).then(() => {
        commit(SET_SERVICE, response.data.service);

        let customerContacts =
          response.data.service.customer_site.customer_contacts;
        let primaryContactId =
          response.data.service.customer_site.customer_contact_id;

        let siteContact = customerContacts.find(
          customerContact => customerContact.id === primaryContactId
        );

        commit(SET_SITE_CONTACT, siteContact);

        dispatch("setServiceSelection");

        commit(DECREMENT_LOADING);
      });
    });
  },
  updateBaseData: ({ commit }) => {
    commit(INCREMENT_LOADING);
    return Vue.axios.get("quotes/load-data").then(response => {
      commit(RESET_ALL_DATA);
      commit(SET_BASE_DATA, response.data);
      commit(DECREMENT_LOADING);
    });
  },
  updateServiceStatus: ({ commit }, service) => {
    commit(INCREMENT_LOADING);
    return Vue.axios
      .post("quotes/update-service-status", {
        serviceId: service.quoteId,
        statusId: service.statusVal
      })
      .then(() => {
        commit(DECREMENT_LOADING);
      });
  },
  updateServiceInfo: ({ commit }, postalCode) => {
    commit(INCREMENT_LOADING);
    commit(SET_SERVICE_INFO, {});
    return Vue.axios
      .get(`quotes/availability/${postalCode}`)
      .then(response => {
        commit(SET_SERVICE_INFO, response.data);
        commit(DECREMENT_LOADING);
      })
      .catch(() => commit(DECREMENT_LOADING));
  },
  updateServiceInfoByCity: ({ commit }, payload) => {
    commit(INCREMENT_LOADING);
    commit(SET_SERVICE_INFO_BY_CITY, {});
    return Vue.axios
      .get(`quotes/availability/${payload.city}/${payload.state}`)
      .then(response => {
        commit(SET_SERVICE_INFO_BY_CITY, response.data);
        commit(DECREMENT_LOADING);
      })
      .catch(() => commit(DECREMENT_LOADING));
  },
  addLoading: ({ commit }) => {
    commit(INCREMENT_LOADING);
  },
  removeLoading: ({ commit }) => {
    commit(DECREMENT_LOADING);
  },
  setServiceSelection({ getters, commit }) {
    // Do nothing if there isn't a matching price group
    if (!Object.keys(getters.selectedPriceGroup).length) return;
    // Set service selection details
    commit(SET_SERVICE_SELECTION, {
      debrisType: getters.selectedPriceGroup.debris_type_id,
      containerType: getters.selectedPriceGroup.container_type_id,
      serviceHaulers: getters.selectedPriceGroup.haulers
    });
  },
  setPlaceData(
    { dispatch, commit, state },
    { place, siteOnly = false, overrideGeo = false }
  ) {
    if (!siteOnly) {
      commit(CLEAR_PLACE_DATA);
    }
    // Extract the address data we care about
    let addressData = getAddressComponents(
      [
        {
          name: "street_number",
          value: "street_number"
        },
        {
          name: "street",
          value: "route"
        },
        {
          name: "city",
          value: ["locality", "neighborhood"]
        },
        {
          name: "state",
          value: "administrative_area_level_1"
        },
        {
          name: "short_state",
          value: "administrative_area_level_1",
          short: true
        },
        {
          name: "country",
          value: "country"
        },
        {
          name: "postal_code",
          value: "postal_code"
        }
      ],
      place.address_components
    );

    const state_id = (
      state.baseData.states.find(
        state => state.text.toLowerCase() === addressData.state.toLowerCase()
      ) || { value: null }
    ).value;

    const latitude =
      typeof place.geometry.location.lat === "function"
        ? place.geometry.location.lat()
        : place.geometry.location.lat;
    const longitude =
      typeof place.geometry.location.lng === "function"
        ? place.geometry.location.lng()
        : place.geometry.location.lng;

    commit(UPDATE_SITE, {
      ...addressData,
      formatted_address: place.formatted_address,
      street_address: `${addressData.street_number} ${addressData.street}`,
      postal_code_display: addressData.postal_code,
      city: addressData.city,
      ...(overrideGeo
        ? {
            latitude: overrideGeo.lat,
            longitude: overrideGeo.lng
          }
        : {
            latitude,
            longitude
          }),
      place,
      state: {
        id: state_id,
        name: addressData.state,
        short_name: addressData.short_state
      },
      postal_code: {
        code: addressData.postal_code
      },
      overrideGeo: overrideGeo !== false
    });

    if (!siteOnly) {
      // If customer information is already set, we don't want to override it with the site info
      if (!state.quote.customer.id) {
        commit(UPDATE_CUSTOMER, {
          street: `${
            addressData.street_number ? addressData.street_number + " " : ""
          }${addressData.street}`,
          city: addressData.city,
          state: {
            name: addressData.state,
            short_name: addressData.short_state,
            id: state_id
          },
          postal_code: {
            code: addressData.postal_code
          }
        });
      }

      // Get the service info
      if (addressData.postal_code) {
        dispatch("updateServiceInfo", addressData.postal_code);
        dispatch("logQuote", {
          action: "get_service_info",
          data: addressData.postal_code,
          qid: window.localStorage.getItem("ro360-qid")
        });
      } else {
        const city = getAddressComponent("locality", place.address_components);
        const state = getAddressComponent(
          "administrative_area_level_1",
          place.address_components,
          true
        );

        dispatch("updateServiceInfoByCity", {
          city,
          state
        });

        dispatch("logQuote", {
          action: "get_service_info_city",
          data: { city, state },
          qid: window.localStorage.getItem("ro360-qid")
        });
      }
    }
  },
  loadCustomerSite: ({ commit, dispatch }, { customer_id, site_id }) => {
    commit(INCREMENT_LOADING);
    commit(SET_MESSAGE, { type: "error", message: "" });

    return dispatch("loadCustomer", { customer_id, site_id })
      .then(({ data: { site, customer } }) => {
        if (site) {
          commit(SET_SITE, site);
        }

        commit(SET_CUSTOMER, customer);

        if (site) {
          dispatch("updateServiceInfo", site.postal_code);
        }

        commit(DECREMENT_LOADING);
      })
      .catch(() => {
        commit(DECREMENT_LOADING);
        commit(SET_MESSAGE, {
          type: "error",
          message: "There was an issue fetching the requested customer/site."
        });
      });
  },
  reset: ({ dispatch, commit }) => {
    commit(RESET_ALL_DATA);
    return dispatch("updateBaseData");
  },
  logQuote: (props, payload) => {
    const { getters } = props;

    // Skip logs for non-public users
    if (getters.userAccess > 0) {
      return false;
    }

    return Vue.axios.post("quotes/log-quote", payload).catch(() => {
      // Do nothing
    });
  },
  saveQuoteToLocalStorage: ({ state }) => {
    console.log("Saving quote to local storage");
    localStorage.setItem("ro360-quote", JSON.stringify(state.quote));
    localStorage.setItem("ro360-site", JSON.stringify(state.site));
    localStorage.setItem("ro360-current-step", state.currentStep);
  },
  restoreQuoteFromLocalStorage: ({ commit, dispatch }) => {
    console.log("Restoring quote from local storage");
    const quote = JSON.parse(localStorage.getItem("ro360-quote"));
    if (quote) {
      commit(SET_QUOTE, quote);
    }

    const site = JSON.parse(localStorage.getItem("ro360-site"));
    if (site) {
      commit(SET_SITE, site);
    }

    const currentStep = localStorage.getItem("ro360-current-step");

    if (currentStep) {
      commit(SET_CURRENT_STEP, currentStep);
    }

    dispatch("setPlaceData", { place: site.place, siteOnly: false });
  },
  logAnalyticsEvent: (
    { getters },
    { event, params } = {
      event: "quote_tool_step_change",
      params: {
        event_label: getters.currentStep ? getters.currentStep.title : ""
      }
    }
  ) => {
    if (window.gtag) {
      window.gtag("event", event, {
        event_category: "quote_tool",
        ...params
      });
    }
  }
};
