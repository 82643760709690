import Vue from "vue";
import gMapVue, { components } from "gmap-vue";
import App from "./AppQuoteToolV2.vue";
import router from "./router/quote-tool-v2";
import vuetify from "./plugins/vuetify";
import Mask from "./plugins/mask";
import validation from "./plugins/validation";
import env from "./env";
import Vuex from "vuex";
import QuotesStore from "./store/modules/quotes";
import baseData from "./store/modules/baseData";
import "@/plugins/date";
import http from "./plugins/http";

Vue.config.productionTip = false;

Vue.filter("toCurrency", function(value) {
  if (typeof value !== "number") {
    return value;
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  return formatter.format(value);
});

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    quotes: QuotesStore(),
    baseData: baseData
  }
});

Vue.use(validation);
Vue.use(Mask);

// Configure gMapVue
Vue.use(gMapVue, {
  load: {
    key: env.api.google,
    libraries: "places",
    v: "3.26"
  },
  installComponents: false
});

// gMapVue Components
const { Marker, Autocomplete, MapLayer, Polygon } = components;

Vue.component("gmap-marker", Marker);
Vue.component("gmap-autocomplete", Autocomplete);
Vue.component("gmap-map", MapLayer);
Vue.component("gmap-polygon", Polygon);

new Vue({
  http,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#" + env.el);
/**
 * Embed Script
 <script>
 (function(i,s,n,g,u,k,a){
 s.write('<div id="'+n+'"></div>');a=s.createElement('script');a.async=true;a.src=g+'?v='+(1* new Date());s.head.appendChild(a);i[n]={u:u,k:k,e:n};
 })(window, document, 'ro360', '/quote-form/js/app.js', '/api/v1/', 'b7ff44ac-5f71-4261-8');
 </script>
 */
