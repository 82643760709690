var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('SectionTitle',{attrs:{"id":"customize-quote-header","title":"Optimize Your Dumpster Rental - Save Time & Money!","subtitle":"Fine-tune your weight allowance and rental period to best suit your project's budget and timeline.","no-divider":true}})],1),(_vm.weightLimits.length)?_c('v-col',{attrs:{"cols":"12"}},[_c('SectionTitle',{attrs:{"id":"weight-allowance-header","title":"Customize My Weight Allowance","subtitle":"Use the options below to customize your order."}}),_c('v-expand-transition',[_c('OptionSelector',{attrs:{"cols":"4","options":_vm.weightLimits},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"headline"},[_vm._v(" "+_vm._s((item.price + _vm.basePrice).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0 }))+" ")]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(Math.abs(item.value))+" tons "),(item.subtitle)?_c('v-divider'):_vm._e(),(item.subtitle)?_c('span',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(item.subtitle)+" ")]):_vm._e()],1),(item.default)?_c('span',{staticClass:"subtitle font-weight-regular font-italic"},[_vm._v(" Standard ")]):_vm._e()]}}],null,false,973615057),model:{value:(_vm.weightLimit),callback:function ($$v) {_vm.weightLimit=$$v},expression:"weightLimit"}})],1)],1):_vm._e(),(_vm.rentalPeriods.length)?_c('v-col',{attrs:{"cols":"12"}},[_c('SectionTitle',{attrs:{"id":"rental-period-header","title":"Tailor My Rental Period","subtitle":"Select a shorter or longer rental that best fits your project."}}),_c('v-expand-transition',[_c('OptionSelector',{attrs:{"options":_vm.rentalPeriods,"cols":"4"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"headline"},[_vm._v(_vm._s(Math.abs(item.value))+" days")]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.price > 0 ? "+" : "-")+" "),(item.price)?[_vm._v(" $"+_vm._s(Math.abs(item.price))+" ")]:_vm._e()],2),(item.default)?_c('span',{staticClass:"subtitle font-weight-regular font-italic"},[_vm._v(" Standard ")]):_vm._e()]}}],null,false,2409490228),model:{value:(_vm.rentalPeriod),callback:function ($$v) {_vm.rentalPeriod=$$v},expression:"rentalPeriod"}})],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"my-1"},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"text-body-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.selectedDebrisType.description)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.selectedContainerType.description)+" ")]),_c('small',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.selectedContainerType.dimensions))])]),_c('v-card-text',{staticClass:"d-flex justify-space-between pt-0"},[_c('div',{staticClass:"text px-1"},[(_vm.selectedContainerType.hint)?_c('p',{staticClass:"mb-0 pr-2",staticStyle:{"font-size":"0.8rem"}},[_vm._v(" "+_vm._s(_vm.selectedContainerType.hint)+" ")]):_vm._e()]),(_vm.selectedContainerType.image_url)?_c('div',{staticClass:"image d-flex justify-end align-end"},[_c('img',{attrs:{"height":"55px","src":_vm.selectedContainerType.image_url}})]):_vm._e()]),_c('v-divider',{staticClass:"mb-0"}),_c('v-card-text',{staticClass:"d-flex flex-column pt-0 px-1"},[_c('v-list',{attrs:{"dense":"","disabled":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_vm._l(([
                  {
                    subtitle: 'Pricing & Details'
                  },
                  {
                    text: 'Total Price',
                    value: ("$" + _vm.currentPrice),
                    icon: 'mdi-currency-usd',
                    bold: true
                  },
                  {
                    text: 'Weight Allowance',
                    value: (_vm.weightAllowance + " tons"),
                    icon: 'mdi-weight-kilogram'
                  },
                  {
                    text: 'Overweight Costs',
                    value: ("$" + (_vm.terms.overweight_rate) + "/ton"),
                    icon: 'mdi-weight-kilogram'
                  },
                  {
                    text: 'Included Rental Period',
                    value: (_vm.rentalPeriodDays + " days"),
                    icon: 'mdi-calendar'
                  },
                  {
                    text: 'Additional Days',
                    value: ("$" + (_vm.terms.daily_rental_rate) + "/day"),
                    icon: 'mdi-calendar-plus'
                  },
                  {
                    subtitle: 'Additional Charge Items'
                  },
                  {
                    text: 'Tires',
                    value: ("$" + (_vm.terms.tires_rate) + "/each"),
                    icon: 'mdi-car-tire-alert'
                  },
                  {
                    text: 'Mattresses',
                    value: ("$" + (_vm.terms.mattresses_rate) + "/each"),
                    icon: 'mdi-bed-king'
                  },
                  {
                    text: 'Couches',
                    value: ("$" + (_vm.terms.couches_rate) + "/each"),
                    icon: 'mdi-sofa'
                  }
                ]),function(item,i){return [(!item.subtitle)?_c('v-list-item',{key:i},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex justify-space-between"},[_c('span',{class:("" + (item.bold ? 'font-weight-bold' : '')),domProps:{"textContent":_vm._s(item.text)}}),_c('span',{domProps:{"textContent":_vm._s(item.value)}})])],1)],1):_c('v-subheader',{key:i},[_vm._v(_vm._s(item.subtitle))])]})],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }