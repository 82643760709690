import rules from "./rules";
import { ValidationProvider, extend } from "vee-validate";
import Vue from "vue";

extend("postalCode", {
  validate: async value => {
    if (value.length < 5) {
      return {
        valid: false
      };
    }

    return Vue.axios.get("validation/postal-code/" + value).then(response => {
      return response.data.valid;
    });
  },
  message: () => {
    return "Invalid postal code";
  }
});

const Index = {
  install(Vue) {
    if (this.installed) return;
    Vue.prototype.$validationRules = rules;
    Vue.component("ValidationProvider", ValidationProvider);
    this.installed = true;
  }
};

export default Index;
