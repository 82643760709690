export default function loadGoogleAnalytics(gaId) {
  // Check if script is already loaded
  if (window.gtag) {
    console.log("Google Analytics already loaded");
    return;
  }

  // Insert Google Analytics script tag
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
  document.head.appendChild(script);

  // Initialize gtag
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", gaId);

  window.gtag = gtag;
}
