<template>
  <div>
    <Confirm ref="confirm" :action="confirmBool" />
    <v-divider class="mb-3"></v-divider>
    <v-toolbar id="step-toolbar" dense flat class="mr-sm-0 ml-sm-0">
      <v-btn
        v-if="userAccess > 0 && quote.service.id && canDelete"
        :disabled="!nextStepEnabled"
        :tile="userAccess > 0"
        color="error"
        :class="nextStepEnabled ? 'white--text' : 'black--text'"
        @click="deleteQuote(quote.service)"
        class="mr-2"
        :id="`delete-${quote.service.id}`"
      >
        Delete Quote <v-icon right>mdi-trash-can</v-icon>
      </v-btn>
      <v-btn
        tabindex="-1"
        :tile="userAccess > 0"
        v-if="previousStep"
        color="secondary"
        @click="previousStep"
      >
        <v-icon left>mdi-chevron-left</v-icon>
        {{ previousStepTitle }}
      </v-btn>
      <v-spacer></v-spacer>
      <slot></slot>
      <template v-if="actions.length">
        <v-btn
          :tile="userAccess > 0"
          :color="action.color || 'primary'"
          :key="action.title"
          class="ml-5"
          @click="action.action"
          v-for="action in actions"
          >{{ action.title }}</v-btn
        >
      </template>

      <v-btn
        :disabled="!nextStepEnabled"
        :tile="userAccess > 0"
        color="action"
        :class="nextStepEnabled ? 'white--text' : 'black--text'"
        @click="nextStep"
        class="ml-5"
        :id="`step-${currentStep}`"
      >
        {{ nextStepTitle }} <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>

      <slot name="after-actions"></slot>
    </v-toolbar>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import Confirm from "@/components/Confirm";

export default {
  props: {
    actions: {
      type: Array,
      default: () => []
    },
    nextStep: {
      type: Function
    },
    nextStepTitle: {
      type: String,
      default: "Continue"
    },
    nextStepEnabled: {
      type: Boolean,
      default: true
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    previousStep: {
      type: Function
    },
    previousStepTitle: {
      type: String,
      default: "Back"
    }
  },
  components: {
    Confirm
  },
  computed: {
    ...mapGetters("quotes", ["userAccess", "customerSiteData"]),
    ...mapState("quotes", ["currentStep", "quote"])
  },
  methods: {
    ...mapActions("quotes", ["updateServiceStatus"]),
    deleteQuote(serviceObj) {
      this.$refs.confirm.open(
        "Delete Quote",
        "Are you sure you want to delete this quote?",
        { color: "red", type: "quote", id: serviceObj }
      );
    },
    confirmBool(quote) {
      if (!quote) {
        this.$refs.confirm.cancel();
      } else {
        this.updateServiceStatus({ quoteId: quote.id.id, statusVal: 8 }).then(
          () => {
            this.$refs.confirm.agree();
            this.$router.push("/quotes/quotes");
            this.$store.dispatch("live/refresh");
          }
        );
      }
    }
  },
  watch: {
    currentStep() {
      if (["xs", "sm", "md"].includes(this.$vuetify.breakpoint.name)) {
        this.$vuetify.goTo(".v-stepper", {});
      }
    }
  }
};
</script>
