<template>
  <v-row>
    <v-col>
      <ClientFields />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import ClientFields from "./ClientFields";

export default {
  name: "CustomerForm",
  props: {
    isService: {
      type: Number
    }
  },
  components: {
    ClientFields
  },
  computed: {
    ...mapGetters("quotes", ["userAccess"])
  }
};
</script>
