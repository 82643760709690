<template>
  <div class="pt-0">
    <v-row>
      <v-col cols="12">
        <SectionTitle
          id="customize-quote-header"
          title="Optimize Your Dumpster Rental - Save Time & Money!"
          subtitle="Fine-tune your weight allowance and rental period to best suit your project's budget and timeline."
          :no-divider="true"
        />
      </v-col>
      <v-col cols="12" v-if="weightLimits.length">
        <SectionTitle
          id="weight-allowance-header"
          title="Customize My Weight Allowance"
          subtitle="Use the options below to customize your order."
        />
        <v-expand-transition>
          <OptionSelector
            cols="4"
            :options="weightLimits"
            v-model="weightLimit"
          >
            <template v-slot:item="{ item }">
              <div class="headline">
                {{
                  (item.price + basePrice).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0
                  })
                }}
              </div>
              <div class="title">
                {{ Math.abs(item.value) }} tons
                <v-divider v-if="item.subtitle"></v-divider>
                <span class="subtitle" v-if="item.subtitle">
                  {{ item.subtitle }}
                </span>
              </div>
              <span
                class="subtitle font-weight-regular font-italic"
                v-if="item.default"
              >
                Standard
              </span>
            </template>
          </OptionSelector>
        </v-expand-transition>
      </v-col>
      <v-col cols="12" v-if="rentalPeriods.length">
        <SectionTitle
          id="rental-period-header"
          title="Tailor My Rental Period"
          subtitle="Select a shorter or longer rental that best fits your project."
        />
        <v-expand-transition>
          <OptionSelector
            :options="rentalPeriods"
            v-model="rentalPeriod"
            cols="4"
          >
            <template v-slot:item="{ item }">
              <div class="headline">{{ Math.abs(item.value) }} days</div>
              <div class="title">
                {{ item.price > 0 ? "+" : "-" }}
                <template v-if="item.price">
                  ${{ Math.abs(item.price) }}
                </template>
              </div>
              <span
                class="subtitle font-weight-regular font-italic"
                v-if="item.default"
              >
                Standard
              </span>
            </template>
          </OptionSelector>
        </v-expand-transition>
      </v-col>
      <v-col cols="12">
        <v-card class="my-1">
          <v-card-title class="d-flex justify-space-between ">
            <div class="text-body-1 font-weight-bold">
              {{ selectedDebrisType.description }}
              <br />
              {{ selectedContainerType.description }}
            </div>
            <small class="text-right ">{{
              selectedContainerType.dimensions
            }}</small>
          </v-card-title>
          <v-card-text class="d-flex justify-space-between pt-0">
            <div class="text px-1">
              <p
                style="font-size:0.8rem;"
                class="mb-0 pr-2"
                v-if="selectedContainerType.hint"
              >
                {{ selectedContainerType.hint }}
              </p>
            </div>
            <div
              v-if="selectedContainerType.image_url"
              class="image d-flex justify-end align-end"
            >
              <img height="55px" :src="selectedContainerType.image_url" />
            </div>
          </v-card-text>
          <v-divider class="mb-0"></v-divider>
          <v-card-text class="d-flex flex-column pt-0 px-1">
            <v-list dense disabled>
              <v-list-item-group color="primary">
                <template
                  v-for="(item, i) in [
                    {
                      subtitle: 'Pricing & Details'
                    },
                    {
                      text: 'Total Price',
                      value: `$${currentPrice}`,
                      icon: 'mdi-currency-usd',
                      bold: true
                    },
                    {
                      text: 'Weight Allowance',
                      value: `${weightAllowance} tons`,
                      icon: 'mdi-weight-kilogram'
                    },
                    {
                      text: 'Overweight Costs',
                      value: `$${terms.overweight_rate}/ton`,
                      icon: 'mdi-weight-kilogram'
                    },
                    {
                      text: 'Included Rental Period',
                      value: `${rentalPeriodDays} days`,
                      icon: 'mdi-calendar'
                    },
                    {
                      text: 'Additional Days',
                      value: `$${terms.daily_rental_rate}/day`,
                      icon: 'mdi-calendar-plus'
                    },
                    {
                      subtitle: 'Additional Charge Items'
                    },
                    {
                      text: 'Tires',
                      value: `$${terms.tires_rate}/each`,
                      icon: 'mdi-car-tire-alert'
                    },
                    {
                      text: 'Mattresses',
                      value: `$${terms.mattresses_rate}/each`,
                      icon: 'mdi-bed-king'
                    },
                    {
                      text: 'Couches',
                      value: `$${terms.couches_rate}/each`,
                      icon: 'mdi-sofa'
                    }
                  ]"
                >
                  <v-list-item v-if="!item.subtitle" :key="i">
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="d-flex justify-space-between">
                        <span
                          :class="`${item.bold ? 'font-weight-bold' : ''}`"
                          v-text="item.text"
                        ></span>
                        <span v-text="item.value"></span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-subheader :key="i" v-else>{{ item.subtitle }}</v-subheader>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("quotes");
import { mapFields } from "vuex-map-fields";
import SectionTitle from "./SectionTitle.vue";
import OptionSelector from "../../utils/OptionSelector.vue";

/**
 * @typedef {object} $validationRules
 * @typedef {int} debrisType
 * @typedef {int} containerType
 * @typedef {string} serviceSelectionId
 */
export default {
  name: "Service",
  components: {
    OptionSelector,
    SectionTitle
  },
  computed: {
    ...mapFields("quotes", {
      debrisType: "quote.service.debrisType",
      containerType: "quote.service.containerType",
      projectType: "quote.service.projectType",
      weightLimit: "quote.service.weightLimit",
      rentalPeriod: "quote.service.rentalPeriod",
      serviceSelectionId: "serviceSelectionId",
      weightLimitObject: "quote.service.weightLimitObject",
      rentalPeriodObject: "quote.service.rentalPeriodObject",
      quote: "quote"
    }),
    ...mapGetters([
      "userAccess",
      "debrisTypes",
      "containerTypes",
      "catalogDetails",
      "projectTypes",
      "determinedPriceGroup",
      "terms",
      "selectedPriceGroup",
      "serviceSelectionPriceModifiers",
      "weightLimits",
      "rentalPeriods"
    ]),
    selectedContainerType() {
      return (
        this.containerTypes.find(c => c.id === this.containerType) || {
          description: "N/A",
          weight_limit: "N/A",
          dimensions: "N/A"
        }
      );
    },
    selectedDebrisType() {
      return (
        this.debrisTypes.find(c => c.id === this.debrisType) || {
          description: "N/A"
        }
      );
    },
    basePrice() {
      return this.determinedPriceGroup?.price
        ? this.determinedPriceGroup.price
        : 0;
    },
    currentPrice() {
      const weightLimitPrice = this.weightLimitObject?.price
        ? this.weightLimitObject.price
        : 0;

      const rentalPeriodPrice = this.rentalPeriodObject?.price
        ? this.rentalPeriodObject.price
        : 0;

      return this.basePrice + weightLimitPrice + rentalPeriodPrice;
    },
    weightAllowance() {
      return this.weightLimitObject?.value
        ? this.weightLimitObject.value
        : this.determinedPriceGroup.weight_allowance;
    },
    rentalPeriodDays() {
      return this.rentalPeriodObject?.value
        ? this.rentalPeriodObject.value
        : this.terms.rental_period_days;
    }
  },
  data() {
    return {
      test: 1,
      menu: false,
      inset: true
    };
  },
  watch: {
    determinedPriceGroup() {
      this.weightLimit = (
        this.weightLimits.find(w => w.default) || { id: 0 }
      ).id;
      this.rentalPeriod = (
        this.rentalPeriods.find(w => w.default) || { id: 0 }
      ).id;
    },
    serviceSelectionId() {
      this.setServiceSelection();
    },
    debrisType: {
      deep: true,
      handler(newVal) {
        if (this.$vuetify.breakpoint.smAndDown && newVal) {
          setTimeout(() => {
            this.$vuetify.goTo("#container-type-header");
          }, 500);
        }
      }
    },
    weightLimit(newVal) {
      this.weightLimitObject = this.weightLimits.find(w => w.id == newVal);

      this.quote.service.overweight_rate = this.weightLimitObject.secondary_term_value;
    },
    rentalPeriod(newVal) {
      this.rentalPeriodObject = this.rentalPeriods.find(w => w.id == newVal);
    }
  },
  methods: {
    ...mapActions(["setServiceSelection"])
  },
  mounted() {
    this.setServiceSelection();
  }
};
</script>

<style>
.selection-card {
  cursor: pointer;
  border: 1px solid #f58319;
  border-radius: 5px;
  margin: 10px 0;
}

.selection-card.selected {
  border: 2px solid #f58319; /* Highlight color */
  background-color: #e0e0e0;
}

.selection-card:hover {
  box-shadow: 0 0 10px #f58319;
}

.option-selector-wrapper span.subtitle {
  padding-top: 0.5rem;
  font-size: 0.7rem;
  color: black;
  display: block;
  font-weight: bold;
  word-break: keep-all;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
}
</style>
