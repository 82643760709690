import Vue from "vue";
import VueRouter from "vue-router";
import QuoteTool from "../components/quote-tool/QuoteToolV2";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      name: "Quote Tool",
      component: QuoteTool
    }
  ]
});

export default router;
