<template>
  <div class="quote-tool-step-content">
    <v-dialog v-model="tosDialog" max-width="700">
      <v-card>
        <v-card-title class="headline">
          Terms and Conditions
        </v-card-title>

        <v-card-text>
          <v-divider class="my-3"></v-divider>
          <h3>AGREEMENT</h3>
          <p>
            Your request for dumpster service is an acknowledgement of the terms
            of this Agreement. You may cancel service without penalty prior to
            the routing of your dumpster delivery by calling us Monday to Friday
            from 7:30 a.m. to 5:00 p.m. (EST).
          </p>
          <h3>OUR STUFF</h3>
          <p>
            We will schedule delivery of the dumpster upon receipt of your
            payment. Deliveries are subject to local governmental restrictions
            on service times and locations. If you load the dumpster
            <b>in excess of the included weight allowance</b> you will incur an
            additional charge for the overweight portion of the load. You
            authorize us to charge your credit card on file for other dumpster
            related charges such as; daily rental fees, damage or loss to our
            vehicles and dumpsters, attempted delivery or removal, dumpster
            relocations, fines, and special disposal handling fees (e.g., tires,
            mattresses, couches, tree stumps, prohibited and hazardous
            materials, etc.). You acknowledge that our dumpsters and vehicles
            could potentially damage your property, including driveway
            pavements, grass, plants and substrates that our vehicles travel
            upon or near during service to your property. You agree not to hold
            us responsible for any such damage, nor for any delays or losses
            caused by acts of nature, strikes, traffic congestion, governmental
            authority, public disturbance, restricted or blocked access to the
            delivery location, force majeure or any other causes known and
            unknown. This Agreement is governed exclusively under the laws of
            the State of Maryland. In the event this Agreement is disputed all
            of the parties agree to be responsible for their own legal fees,
            costs and expenses.
          </p>
          <h3>YOUR STUFF</h3>
          <p>
            You accept responsibility for damage and loss caused by graffiti,
            fire, machinery, vehicles and theft while the dumpster is in your
            possession. You acknowledge your complete and full authority to
            grant our vehicles and employees access to the dumpster delivery
            site. You agree not to overload our dumpster beyond its height,
            width and weight capacity nor place any item that is toxic,
            hazardous, or prohibited by law into our dumpster. You agree to
            defend, indemnify and hold us harmless from all claims, lawsuits and
            liability for injury to persons and damage to property, third
            parties or the environment resulting from all negligent,
            unauthorized or improper use of our dumpster. You promise to inform
            us immediately if our service is not
            <b>REMARKABLE</b>.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="tosDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row v-if="quoteError">
      <v-col>
        <v-alert type="error"
          >{{ quoteError }} <span v-html="quoteErrors" v-if="userAccess > 0"
        /></v-alert>
      </v-col>
    </v-row>

    <v-form @submit.prevent="submit" ref="form" v-model="valid">
      <v-row>
        <v-col v-if="error || paymentError.error" cols="12">
          <v-alert
            type="error"
            v-html="paymentError.error"
            v-if="paymentError.error"
          />
          <v-alert type="error" v-html="error" v-if="error.length" />
        </v-col>
        <v-col cols="12">
          <QuoteSection
            :meta="{
              title: 'Contact Information',
              subtitle: 'Please enter your contact information.'
            }"
          >
            <v-container fluid class="pa-0">
              <ClientFields :purchase-order="true" />
            </v-container>
          </QuoteSection>
        </v-col>

        <v-col cols="12">
          <QuoteSection
            :meta="{
              title: 'Date & Instructions',
              subtitle: 'Please review the date.'
            }"
            :show-edit="false"
          >
            <v-row v-if="quoteSections.delivery">
              <v-col cols="12">
                <v-alert
                  type="warning"
                  text
                  v-if="dateError"
                  v-html="dateError"
                />
                <v-row>
                  <ScheduleDateInput />
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-label>Preferred Delivery Time</v-label>
                    <v-radio-group v-model="time_range" row>
                      <v-radio label="AM" value="am"></v-radio>
                      <v-radio label="PM" value="pm"></v-radio>
                      <v-radio label="Anytime" value="any"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Placement Option"
                  v-model="placement_option"
                  :items="placement_options"
                ></v-select>
              </v-col>
              <v-col v-if="placement_option === 'Other'" cols="12">
                <v-textarea
                  label="Placement Option - Other"
                  v-model="placement_option_other"
                  rows="1"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Additional Instructions"
                  v-model="work_order_note"
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </QuoteSection>
          <QuoteSection
            :meta="{
              title: this.paymentTitle,
              subtitle: this.paymentSubtitle
            }"
            :show-edit="!!this.paymentResult"
            @edit="editPayment"
            :error="paymentError.error !== ''"
          >
            <v-row>
              <v-col v-if="quote.error" cols="12">
                <v-alert type="error">{{ quote.error }}</v-alert>
              </v-col>
              <v-col cols="12" md="12" class="mb-0 pb-0">
                <v-text-field
                  prepend-inner-icon="mdi-credit-card"
                  v-model="payment.cardNumber"
                  label="Card Number"
                  type="tel"
                  :rules="rules.cardNumber"
                  name="cardNumber"
                  id="cardNumber"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-text-field
                  prepend-inner-icon="mdi-calendar-month"
                  label="Exp Month"
                  v-model="payment.expMonth"
                  type="tel"
                  :rules="rules.expMonth"
                  name="expMonth"
                  id="expMonth"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-text-field
                  prepend-inner-icon="mdi-calendar-month"
                  label="Exp Year"
                  v-model="payment.expYear"
                  type="tel"
                  :rules="rules.expYear"
                  name="expYear"
                  id="expYear"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  prepend-inner-icon="mdi-lock"
                  label="Card Code"
                  type="tel"
                  v-model="payment.cardCode"
                  :rules="rules.cardCode"
                  name="cardCode"
                  id="cardCode"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  v-model="payment.first_name"
                  label="First Name"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  v-model="payment.last_name"
                  label="Last Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="useDeliveryAddress"
                  label="Delivery address is same as billing address."
                />
              </v-col>
              <template v-if="!useDeliveryAddress">
                <v-col cols="12">
                  <v-text-field
                    :disabled="useDeliveryAddress"
                    v-model="payment.street"
                    label="Street"
                    :rules="$validationRules.required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :disabled="useDeliveryAddress"
                    v-model="payment.city"
                    label="City"
                    :rules="$validationRules.required"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2">
                  <v-text-field
                    :disabled="useDeliveryAddress"
                    v-model="payment.state"
                    label="State"
                    :rules="$validationRules.required"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                  <v-text-field
                    :disabled="useDeliveryAddress"
                    v-model="payment.postal_code_display"
                    label="Postal Code"
                    :rules="$validationRules.required"
                    type="tel"
                    maxlength="5"
                  ></v-text-field>
                </v-col>
              </template>
            </v-row>
          </QuoteSection>
        </v-col>
        <v-col cols="12">
          <v-row no-gutters>
            <v-col cols="12" md="12" class="mt-0 pt-0 pb-0">
              <v-checkbox
                dense
                v-model="requiredTerms.creditCardAuthorization"
                :rules="rules.requiredCheckbox"
              >
                <template v-slot:label>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a target="_blank" v-on="on">
                          Credit Card Authorization
                        </a>
                      </template>
                      I authorize Next Day Dumpsters to charge my credit card on
                      file for additional dumpster-related charges, including
                      disposal overweight costs, daily rental fees, relocation
                      and service attempt fees, special disposal handling fees
                      (e.g., mattresses, tires), fines, and damages incurred to
                      their equipment during the rental.
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                dense
                v-model="requiredTerms.propertyDamage"
                :rules="rules.requiredCheckbox"
              >
                <template v-slot:label>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a target="_blank" v-on="on">
                          Property Damages
                        </a>
                      </template>
                      I understand that Next Day Dumpsters cannot be held
                      responsible for any damage caused by their trucks and
                      dumpsters to driveways, curbs, landscaping, and
                      surrounding surfaces. I acknowledge that providing plywood
                      for the drivers to place under the wheels and front rails
                      of the dumpster can help minimize potential damage.
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                dense
                v-model="requiredTerms.scheduleFinalPickup"
                :rules="rules.requiredCheckbox"
              >
                <template v-slot:label>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a target="_blank" v-on="on">
                          Scheduling Final Pick Ups
                        </a>
                      </template>
                      I understand that Next Day Dumpsters does not
                      automatically schedule final pick-ups for their services.
                      I will contact their customer support or use the Manage My
                      Service portal to schedule the pick-up on my preferred
                      date.
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                dense
                v-model="requiredTerms.overweightDebrisCharges"
                :rules="rules.requiredCheckbox"
              >
                <template v-slot:label>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a target="_blank" v-on="on">
                          Overweight Debris Charges
                        </a>
                      </template>
                      I understand that if the debris loaded in the dumpster
                      exceeds the weight allowance included with my service, I
                      will incur additional charges for the overweight portion
                      of the load. This charge will be calculated at the
                      prorated rate specified in the terms of my service. I will
                      ensure the debris is evenly loaded and kept below the top
                      edges of the dumpster. Improperly loading the dumpster may
                      require offloading until it is safe to transport.
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                dense
                v-model="requiredTerms.serviceAttemptFee"
                :rules="rules.requiredCheckbox"
              >
                <template v-slot:label>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a target="_blank" v-on="on">
                          Service Attempt Fee
                        </a>
                      </template>
                      I understand that if Next Day Dumpsters encounters
                      circumstances beyond its control, such as inaccessible
                      driveways or placement locations, blocked access, locked
                      gates, fences or parking lots, improperly loaded
                      dumpsters, low-lying power lines or tree branches, or
                      damaged equipment during service, a Service Attempt fee of
                      $150 will be incurred.
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                dense
                v-model="requiredTerms.acceptTerms"
                :rules="rules.requiredCheckbox"
              >
                <template v-slot:label>
                  <div>
                    I accept the
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          @click.stop.prevent="tosDialog = true"
                          v-on="on"
                        >
                          Terms and Conditions
                        </a>
                      </template>
                      Opens in modal window.
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-alert type="info"
            >Your payment method will be saved and may be charged later if you
            request a dumpster exchange and/or incur any fees related to your
            rental.
          </v-alert>
        </v-col>
      </v-row>
    </v-form>
    <StepControls
      class="pt-4"
      :nextStepEnabled="true"
      :nextStep="isService ? submit : sendQuoteToCustomer"
      :previousStep="previousStep"
      :actions="actions"
      :nextStepTitle="nextStepTitle"
    >
    </StepControls>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import StepControls from "../StepControls";
import { mapFields } from "vuex-map-fields";
import QuoteSection from "../utils/QuoteSection.vue";
import ClientFields from "../steps-v2/components/customer/ClientFields.vue";
import { getAnetEndpoint } from "@/composables";
import ScheduleDateInput from "@/components/quote-tool/shared/ScheduleDateInput.vue";

export default {
  name: "Review",
  props: ["nextStep", "previousStep"],
  components: {
    ScheduleDateInput,
    ClientFields,
    QuoteSection,
    StepControls
  },
  data() {
    return {
      tosDialog: false,
      requiredTerms: {
        creditCardAuthorization: false,
        propertyDamage: false,
        scheduleFinalPickup: false,
        overweightDebrisCharges: false,
        serviceAttemptFee: false,
        acceptTerms: false
      },

      useDeliveryAddress: true,
      paymentResult: null,
      quoteSections: {
        delivery: true,
        payment: true
      },
      valid: false,
      dateError: "",
      placement_options: [
        "Driveway",
        "Driveway - Left Side Facing House",
        "Driveway - Right Side Facing House",
        "On Street - Some locations require permit for street parking",
        "Other"
      ],
      paymentError: { error: "" },
      error: ""
    };
  },
  computed: {
    rules() {
      let month = this.payment.expMonth;
      let year = this.payment.expYear;

      // If month is less than 10, add a leading zero
      if (month < 10) {
        month = `0${month}`;
      }

      // If year is less than 100, add 2000 to it
      if (year < 100) {
        year = `20${year}`;
      }

      console.log("this.payment", this.payment);
      return {
        cardCode: this.$validationRules.creditCardCode(this.payment.cardNumber),
        cardNumber: this.$validationRules.creditCard,
        expYear: this.$validationRules.futureDateByUnit({
          month,
          year,
          unit: "year"
        }),
        expMonth: this.$validationRules.futureDateByUnit({
          month,
          year,
          unit: "month"
        }),
        requiredCheckbox: this.$validationRules.required
      };
    },
    paymentTitle() {
      return this.paymentResult ? `Payment Information` : "Payment Information";
    },
    paymentSubtitle() {
      return this.paymentError.error
        ? this.paymentError.error
        : `Please enter your payment information.`;
    },
    ...mapGetters("quotes", ["payload"]),
    cardData() {
      return {
        fullName: `${this.payment.first_name} ${this.payment.last_name}`,
        cardNumber: this.payment.cardNumber,
        cardCode: this.payment.cardCode,
        month: this.payment.expMonth,
        year: this.payment.expYear,
        zip:
          this.payment.postal_code_display ||
          this.quote.customer.postal_code.code
      };
    },
    ...mapState("quotes", ["isService", "quote"]),
    ...mapFields("quotes", ["userAccess", "quoteError"]),
    ...mapFields("quotes", ["sendQuote"]),
    ...mapFields("quotes", ["quote.service.preferred_delivery_date"]),
    ...mapFields("quotes", ["quote.service.time_range"]),
    ...mapFields("quotes", ["quote.service.placement_option"]),
    ...mapFields("quotes", ["quote.service.placement_option_other"]),
    ...mapFields("quotes", ["quote.service.work_order_note"]),
    ...mapFields("quotes", ["quote.customer.firstName"]),
    ...mapFields("quotes", ["quote.customer.lastName"]),
    ...mapFields("quotes", ["quote.payment"]),
    ...mapGetters("quotes", ["anetKeys"]),
    preferredDeliveryDateFormatted: {
      get() {
        return this.preferred_delivery_date
          ? this.$date(this.preferred_delivery_date).format("MM/DD/YYYY")
          : "";
      },
      set(newVal) {
        this.preferred_delivery_date = this.$date(newVal).format("YYYY-MM-DD");
      }
    },
    quoteErrors() {
      if (Object.keys(this.quote.errors).length === 0) {
        return false;
      }

      let parsedErrors = Object.assign(
        {},
        ...(function _flatten(o) {
          return [].concat(
            ...Object.keys(o).map(k =>
              typeof o[k] === "object" &&
              !Object.keys(o[k]).filter(key =>
                ["_empty", "minLength", "_required"].includes(key)
              ).length
                ? _flatten(o[k])
                : {
                    [k]:
                      typeof o[k] === "object"
                        ? Object.values(o[k]).join(", ")
                        : o[k]
                  }
            )
          );
        })(this.quote.errors)
      );

      let errors = Object.keys(parsedErrors)
        .map(k => `<b>${k}</b>: ${parsedErrors[k]}`)
        .join(", ");

      return this.quote.errors ? `<br> Validation Errors: ${errors}.` : false;
    },
    actions() {
      return [];
    },
    nextStepTitle() {
      if (this.quote.purchaseService) {
        return "Complete Purchase";
      } else {
        return "Save and Proceed";
      }
    }
  },
  mounted() {
    this.initializeAuthorizeNet();

    this.preferred_delivery_date = this.quote.service.preferredDropoffDate;

    this.payment.first_name = this.firstName;
    this.payment.last_name = this.lastName;
  },
  methods: {
    ...mapMutations("quotes", ["RESET_STATE"]),
    sendQuoteToCustomer() {
      this.sendQuote = true;
      this.submit();
    },
    submit() {
      this.savePayment();
    },
    savePayment() {
      this.paymentError.error = "";

      if (this.$refs.form.validate()) {
        this.sendPaymentDataToAnet().then(result => {
          if (result.success) {
            this.payment.dataDescriptor = result.dataDescriptor;
            this.payment.dataValue = result.dataValue;

            this.nextStep();
          } else {
            this.paymentError.error = result.message;
          }
        });
      }
    },
    editPayment() {
      this.paymentResult = null;
    },
    sendPaymentDataToAnet() {
      // I want this to return a promise so that I can wait for the payment to be processed before moving on
      return new Promise(resolve => {
        this.initializeAuthorizeNet().then(() => {
          const dispatchData = {
            authData: {
              clientKey: this.anetKeys.clientKey,
              apiLoginID: this.anetKeys.apiLoginID
            },
            cardData: this.cardData
          };

          // Dispatch the Accept request
          window.Accept.dispatchData(dispatchData, result => {
            if (result.opaqueData) {
              this.paymentResult = result;
              this.payment.dataDescriptor = result.opaqueData.dataDescriptor;
              this.payment.dataValue = result.opaqueData.dataValue;

              resolve({
                success: true,
                message: "Payment information is valid.",
                dataDescriptor: result.opaqueData.dataDescriptor,
                dataValue: result.opaqueData.dataValue
              });
              return;
            }

            // Extract the error message from the result
            const errorMessage = result.messages.message
              .map(message => message.text)
              .join(", ");

            resolve({
              success: false,
              message: errorMessage
            });
          });
        });
      });
    },
    processAnetPayment(authorizeResult) {
      // Check for responses that don't match what we're expecting from Authorize
      if (
        !authorizeResult ||
        !authorizeResult.messages ||
        !authorizeResult.messages.resultCode
      ) {
        this.error = "Pre-payment Error";
        return;
      }
      // Get our result code in lower case format
      const resultCode = authorizeResult.messages.resultCode.toLowerCase();
      // If result isn't ok, then display a corresponding error message
      if (resultCode !== "ok") {
        this.error =
          authorizeResult.messages.message &&
          authorizeResult.messages.message.length
            ? authorizeResult.messages.message
                .map(message => message.text)
                .join(", ")
            : "Unknown Payment Authorization Error";
      } else {
        this.error = "";
        this.processPayment({
          dataDescriptor: authorizeResult.opaqueData.dataDescriptor,
          dataValue: authorizeResult.opaqueData.dataValue,
          ...this.requestData
          // service: this.service,
          // customer: this.customer,
          // additionalData: additionalData
        }).then(() => {
          this.$router.push({
            name: "viewWorkOrder",
            params: {
              workOrderId: this.workOrderDelivery.id
            }
          });
        });
      }
    },
    initializeAuthorizeNet() {
      return new Promise(function(resolve, reject) {
        if (window.Accept) {
          resolve();
        } else {
          let scriptElm = document.createElement("script");
          scriptElm.setAttribute("type", "text/javascript");
          scriptElm.setAttribute("charset", "utf-8");
          scriptElm.src = getAnetEndpoint();

          scriptElm.onerror = err => {
            console.error(err);
            reject("There was an issue loading the payment processor.");
          };

          scriptElm.onload = resolve;
          window.document.body.appendChild(scriptElm);
        }
      });
    }
  },
  watch: {
    firstName(val) {
      this.payment.first_name = val;
    },
    lastName(val) {
      this.payment.last_name = val;
    },
    // We want to revalidate all the payment fields when the payment object changes
    payment: {
      handler() {
        // This is a bit of a hack, but it works for now
        const paymentFields = ["cardNumber", "expMonth", "expYear", "cardCode"];

        paymentFields.forEach(field => {
          const formField = this.$refs.form.inputs.find(f => f.id === field);

          if (formField) {
            formField.validate();
          }
        });
      },
      deep: true,
      immediate: false
    }
  }
};
</script>

<style scoped></style>
