import dayjs from "dayjs";

const defaultConfig = {
  title: "Secure Your Quote and Schedule in Minutes!",
  topTitle: "Get An Instant Quote and Order Online",
  subtitle:
    "Unlock transparent pricing instantly - no surprises. Tailor your service to match your budget and needs. In just a few taps, secure your quote and schedule your delivery, hassle-free.",
  headerColor: "white darken-2",
  showProjectTypes: true,
  hideStepSubtitles: true,
  hideTopTitleBar: true,
  steps: [
    {
      title: "Enter Service Address & Delivery Date",
      subtitle: "Please enter your service address.",
      component: "LocationStep",
      icon: "mdi-map-marker",
      config: {
        deliveryAddressTitle: "Enter Service Address",
        deliveryAddressSubtitle:
          "Enter service address to confirm availability and to get an accurate quote.",
        deliveryDateTitle: "Preferred Delivery Date",
        deliveryDateSubtitle: "You can schedule up to 60 days in the future."
      }
    },
    {
      title: "Your Contact Information",
      subtitle: "Please enter your contact information.",
      component: "InitialCustomerStep",
      icon: "mdi-account"
    },
    {
      title: "Project Details",
      subtitle: "What type of project is this?",
      component: "ProjectTypeStep",
      icon: "mdi-hammer-screwdriver"
    },
    {
      title: "Choose Debris Type",
      subtitle: "What type of materials are you disposing of?",
      component: "MaterialTypeStep",
      icon: "mdi-recycle"
    },
    {
      title: "Select Dumpster Size",
      subtitle: "How much debris do you have?",
      component: "ContainerSizeStep",
      icon: "mdi-dump-truck"
    },
    {
      title: "Review & Customize Quote",
      subtitle: "",
      component: "ServiceStep",
      icon: "mdi-check"
    },
    {
      title: "Email Quote or Schedule Delivery",
      subtitle: "",
      component: "CustomerStep",
      icon: "mdi-account"
    },
    {
      title: "Email Quote or Schedule Delivery",
      component: "Review",
      icon: "mdi-cart"
    },
    {
      title: "Finish",
      component: "FinishStep",
      icon: "mdi-check",
      hiddenTitle: true
    }
  ]
};

const workOrder = function(type = "delivery") {
  return {
    start_date: "",
    end_date: "",
    date_cadence: "",
    service_date:
      type === "delivery"
        ? dayjs()
            .add(1, "day")
            .format("YYYY-MM-DD")
        : "",
    time_range: "any",
    type_id: type === "delivery" ? 1 : 4,
    notes: "",
    time_window_start: null,
    time_window_end: null,
    status_id: type === "delivery" ? 2 : 1
  };
};

const initData = function() {
  return {
    currentConfig: { ...defaultConfig },
    defaultConfig,
    completedSteps: [],
    purchaseService: false,
    preventRedirects: false,
    sendQuote: false,
    redirectUrl: "",
    messages: {
      error: "",
      success: ""
    },
    baseData: {},
    serviceInfo: {
      paymentData: {}
    },
    serviceInfoByCity: {},
    serviceInfoOverrides: {},
    searchResults: {
      geolocation: {
        sites: []
      },
      quotes: {
        exact_matches: [],
        partial_matches: [],
        success: false,
        message: "",
        doneDupeChecking: false
      },
      customer: {
        matches: [],
        success: false,
        message: "",
        doneDupeChecking: false,
        useForm: false,
        hideForm: false
      }
    },
    currentStep: 1,
    steps: [
      {
        title: "Service Details",
        subtitle: "",
        component: "LocationStep",
        editable: true
      },
      {
        title: "Date & Instructions",
        subtitle: "",
        component: "DateStep",
        editable: false
      },
      {
        title: "Contact Information",
        subtitle: "",
        component: "CustomerStep",
        editable: false
      },
      {
        title: "Review",
        component: "Review",
        editable: false,
        cre: true
      },
      {
        title: "Finish",
        component: "FinishStep",
        editable: false
      }
    ],
    userSteps: [
      {
        title: "Service Details",
        subtitle: "Please enter your service details.",
        component: "LocationStep",
        editable: true
      },
      {
        title: "Contact Information",
        subtitle: "Please enter your contact information.",
        component: "InitialCustomerStep",
        icon: "mdi-account",
        editable: true
      },
      {
        title: "Project Type",
        subtitle: "What type of project is this?",
        component: "ProjectTypeStep",
        editable: true
      },
      {
        title: "Select Material",
        subtitle: "What type of materials are you disposing of?",
        component: "MaterialTypeStep",
        editable: true
      },
      {
        title: "Container Size",
        subtitle: "How much debris do you have?",
        component: "ContainerSizeStep",
        editable: true
      },
      {
        title: "Review Quote",
        subtitle: "",
        component: "ServiceStep",
        editable: true
      },
      {
        title: "Contact Information",
        subtitle: "",
        component: "CustomerStep",
        editable: false
      },
      {
        title: "Purchase Service",
        component: "Review",
        editable: false
      },
      {
        title: "Finish",
        component: "FinishStep",
        editable: false
      }
    ],
    isService: 1, // Indicates if this is a service or a quote
    serviceSelectionId: "",
    serviceHaulers: [],
    selectedHauler: 0,
    siteContact: {
      id: 0,
      pending_id: 0,
      first_name: "",
      last_name: "",
      phone: "",
      phone_extension: "",
      phone2: "",
      phone2_extension: "",
      email: "",
      is_primary: false,
      is_billing: false
    },
    siteContacts: [],
    site: {
      id: 0,
      pending_id: 0,
      customer_id: "",
      formatted_address: "",
      street_number: "",
      street: "",
      city: "",
      state: {
        id: "",
        short_name: "",
        name: ""
      },
      postal_code: {
        id: "",
        code: ""
      },
      latitude: "",
      longitude: "",
      accuracy: "",
      notes: "",
      site_title: "",
      place: {},
      overrideGeo: false
    },
    quote: {
      forceUserAccess: false,
      paymentResult: null,
      serviceId: 0,
      purchaseService: false,
      created: false,
      error: false,
      errors: [],
      payment: {
        dataValue: "",
        dataDescriptor: "",
        first_name: "",
        last_name: "",
        street: "",
        city: "",
        state: "",
        postal_code_display: "",
        phone: "",
        email: "",
        preferred_delivery_date: "",
        work_order_note: "",
        time_range: "any",
        service_id: 0
      },
      customer: {
        final_pickup_billing: false,
        customer_type_id: null,
        customer_source_id: null,
        secondary_info: "",
        firstName: "",
        lastName: "",
        companyName: "",
        phoneNumber: "",
        emailAddress: "",
        notes: "",
        charge_at_sale: 1,
        street: "",
        city: "",
        state: {
          id: "",
          short_name: "",
          name: ""
        },
        postal_code: {
          id: "",
          code: ""
        },
        id: 0,
        pending_id: 0,
        billing_term_id: 0
      },
      service: {
        override_price: null,
        override_exchange_price: null,
        override_description: "",
        override_reason: "",
        manage_services_quote: 0,
        daily_rental_rate: null,
        live_load_wait_rate: null,
        mattresses_rate: null,
        couches_rate: null,
        overweight_rate: null,
        relocate_from_previous_site_rate: null,
        relocate_on_site_rate: null,
        service_attempt_rate: null,
        tires_rate: null,
        rental_period_days: null,
        original_rental_period_days: null,
        customer_id: null,
        project_type_id: null,
        weightLimit: 0,
        weightLimitObject: { id: 0 },
        rentalPeriod: 0,
        rentalPeriodObject: { id: 0 },
        projectType: "",
        containerType: "",
        debrisType: "",
        preferredDropoffDate: "",
        hauler: "",
        weight_allowance: "",
        referral_notes: "",
        delivery_time: "any",
        time_window_start: "",
        time_window_end: "",
        email_comments: "",
        service_type: "delivery",
        preschedule_pickup: false,
        po_number: "",
        notes: "",
        customer_notes: "",
        pickup: {
          delivery_time: "any",
          service_date: "",
          time_window_start: "",
          time_window_end: ""
        },
        service_status: { id: 0, status: "" },
        schedule_recurring_activity: false,
        disable_automatic_dre: false,
        preferred_delivery_date: "",
        placement_option: "",
        placement_option_other: "",
      }
    },
    workOrders: {
      delivery: workOrder(),
      final_pickup: workOrder("final_pickup"),
      recurring: workOrder()
    },
    recurringWorkOrders: [],
    loading: 0
  };
};

export { initData as default, initData, workOrder };
