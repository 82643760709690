<template>
  <div class="px-0">
    <v-toolbar-title class="d-flex font-weight-bold mb-0 p-0">
      <v-icon v-if="error && error !== ''" left color="red">
        mdi-alert-circle
      </v-icon>
      <span :class="`${error && error !== '' ? 'red--text' : ''}`">{{
        meta.title || "..."
      }}</span>

      <v-spacer></v-spacer>

      <v-btn icon x-small v-if="showEdit" @click="$emit('edit', true)">
        <v-icon :color="color">{{ editIcon || "mdi-pencil" }}</v-icon>
      </v-btn>
    </v-toolbar-title>
    <v-toolbar-title v-if="meta.subtitle" class="subtitle-1 pb-1 pt-0 mt-0">
      <slot name="subtitle">{{ meta.subtitle }}</slot>
    </v-toolbar-title>

    <v-divider class="mb-2"></v-divider>
    <div class="quote-section-wrapper ">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "QuoteSection",
  props: {
    error: {
      type: Boolean,
      default: false
    },
    meta: {
      type: Object,
      default: () => ({
        title: "",
        subtitle: ""
      })
    },
    showEdit: {
      type: Boolean,
      default: false
    },
    editStep: {
      type: Function
    },
    color: {
      type: String,
      default: "primary"
    },
    editIcon: {
      type: String
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapFields("quotes", ["currentStep"])
  }
};
</script>
