<template>
  <v-row class="option-selector-wrapper" no-gutters>
    <v-col :cols="cols" v-for="item in options" :key="item.id">
      <v-card
        :class="[
          'selection-card',
          {
            selected:
              item.id === selected ||
              (item.default && !item.id && selected === 0)
          }
        ]"
        class="pa-2 text-center ma-2"
        @click="select(item.id)"
      >
        <slot name="item" :item="item"></slot>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "OptionSelector",
  props: {
    value: {
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    cols: {
      type: String,
      default: "6"
    }
  },
  computed: {
    selected() {
      return this.value;
    }
  },
  methods: {
    select(id) {
      this.$emit("input", id);
    }
  }
};
</script>

<style>
.selection-card {
  cursor: pointer;
  border: 1px solid #f58319;
  border-radius: 5px;
  margin: 10px 0;
}

.selection-card.selected {
  border: 2px solid #f58319; /* Highlight color */
  background-color: #e0e0e0;
}

.selection-card:hover {
  box-shadow: 0 0 10px #f58319;
}

.selection-card .title {
  font-size: 1.2em;
  font-weight: bold;
  color: #f58319;
}

.selection-card .headerline {
  border-bottom: 1px solid #f58319;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 1.2em;
  font-weight: bold;
  color: #f58319;
}
</style>
