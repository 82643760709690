<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel(false)"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4">
        <div v-html="messageFormatted"></div>
        <v-text-field
          v-if="options.input"
          label="Email Recipients (comma or semi-colon separate multiple)"
          v-model="options.inputVal"
          :rules="$validationRules.required"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="agree">{{
          options.yesBtn
        }}</v-btn>
        <v-btn color="grey" text @click="cancel(false)">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["action"],
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: "primary",
      width: 600,
      zIndex: 200,
      type: null,
      id: null,
      input: null,
      inputVal: null,
      yesBtn: "Yes"
    }
  }),
  computed: {
    messageFormatted: function() {
      return this.message;
    }
  },
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.message = message;
      this.options = Object.assign(this.options, options);
      this.title = title;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      // Handle fallback action
      // TODO: Convert existing code to use promises instead of passing in action
      if (this.action) {
        this.action(this.options);
      } else {
        this.resolve(true);
        this.dialog = false;
      }
    },
    cancel(useAction = false) {
      // Handle fallback action
      // TODO: Convert existing code to use promises instead of passing in action
      if (this.action && useAction) {
        this.action(false);
      } else {
        this.resolve(false);
        this.dialog = false;
      }
    }
  }
};
</script>
