var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"option-selector-wrapper",attrs:{"no-gutters":""}},_vm._l((_vm.options),function(item){return _c('v-col',{key:item.id,attrs:{"cols":_vm.cols}},[_c('v-card',{staticClass:"pa-2 text-center ma-2",class:[
        'selection-card',
        {
          selected:
            item.id === _vm.selected ||
            (item.default && !item.id && _vm.selected === 0)
        }
      ],on:{"click":function($event){return _vm.select(item.id)}}},[_vm._t("item",null,{"item":item})],2)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }