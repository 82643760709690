<template>
  <div class="quote-tool-step-content">
    <v-form @submit.prevent="submit" ref="form" v-model="valid">
      <Location :step-object="stepObject" @location-status="handleNext" />
      <v-row>
        <ScheduleDateInput prepend-icon="mdi-calendar" />
      </v-row>
    </v-form>
    <PublicStepControls
      :nextStepEnabled="allowNextStep"
      :nextStep="hasStepBeenCompleted(stepObject.component) ? nextStep : null"
    >
    </PublicStepControls>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import { UPDATE_STEP_METADATA } from "@/store/modules/quotes/mutation-types";
import Location from "./components/Location";

import PublicStepControls from "../PublicStepControls.vue";
import ScheduleDateInput from "@/components/quote-tool/shared/ScheduleDateInput.vue";

/**
 * @typedef {int} isService
 * @typedef {bool} validLocation
 * */
export default {
  name: "LocationStep",
  props: ["nextStep", "stepObject"],
  components: {
    ScheduleDateInput,
    PublicStepControls,
    Location
  },
  computed: {
    ...mapGetters("quotes", [
      "getFormattedDate",
      "getIsHaulerDateAllowed",
      "hasStepBeenCompleted"
    ]),
    ...mapFields("quotes", ["isService", "quote", "loading", "currentStep"]),
    ...mapGetters("quotes", ["validPartialLocation", "validLocation"]),
    ...mapGetters("baseData", ["work_order_types"]),
    ...mapFields("quotes", {
      preferredDropoffDate: "quote.service.preferredDropoffDate",
      serviceDate: "workOrders.delivery.service_date",
      serviceType: "quote.service.service_type",
      dre: "quote.service.rental_period_days",
      preschedulePickup: "quote.service.preschedule_pickup",
      scheduleRecurringActivity: "quote.service.schedule_recurring_activity",
      pickupDate: "workOrders.final_pickup.service_date",
      pickupStatus: "workOrders.final_pickup.status_id",
      pickupTime: "workOrders.final_pickup.time_range",
      pickupTimeStart: "workOrders.final_pickup.time_window_start",
      pickupTimeEnd: "workOrders.final_pickup.time_window_end",
      workOrderNotes: "workOrders.delivery.notes",
      serviceNotes: "quote.service.notes",
      siteNotes: "site.notes",
      lat: "site.latitude",
      lng: "site.longitude",
      address: "site.formatted_address",
      poNumber: "quote.service.po_number",
      customerNotes: "quote.service.customer_notes",
      serviceId: "quote.service.id",
      recurringStartDate: "workOrders.recurring.start_date",
      recurringEndDate: "workOrders.recurring.end_date",
      recurring: "workOrders.recurring",
      scheduledActivities: "recurringWorkOrders",
      workOrders: "workOrders",
      timeRange: "workOrders.recurring.time_range",
      timeWindowStart: "workOrders.recurring.time_window_start",
      timeWindowEnd: "workOrders.recurring.time_window_end",
      recurringType: "workOrders.recurring.type_id",
      disableAutomaticDRE: "quote.service.disable_automatic_dre"
    }),
    ...mapFields("quotes", ["searchResults"]),
    allowNextStep() {
      return this.validLocation === true && this.valid;
    },
    dateRange() {
      const min = this.$date();
      return {
        today: min.format("YYYY-MM-DD"),
        sixMonths: min.add(6, "month").format("YYYY-MM-DD"),
        sixtyDays: min.add(60, "day").format("YYYY-MM-DD")
      };
    }
  },
  methods: {
    getEvent(val) {
      if (!val) return false;
      if (this.userAccess < 1) return false;

      let date = this.$date(val);

      return !this.getIsHaulerDateAllowed(date) &&
        date.isSameOrAfter(this.$date())
        ? "red"
        : false;
    },
    allowedDates(val, forceCheck = false) {
      if (this.userAccess > 0 && !forceCheck) {
        return true;
      }

      let date = this.$date(val);

      return this.getIsHaulerDateAllowed(date);
    },
    handleNext() {
      this.submit();
    },
    ...mapMutations("quotes", [UPDATE_STEP_METADATA]),
    updateStepContinue(val) {
      // Set whether this step can continue or not
      this[UPDATE_STEP_METADATA]({
        component: this.$options.name,
        canContinue: val
      });
    },
    submit() {
      if (
        this.stepObject.component === "LocationStep" &&
        this.allowNextStep &&
        this.$refs.form.validate()
      ) {
        this.nextStep();
      }
    }
  },
  data() {
    return {
      userAccess: 0,
      valid: false,
      dateMenu: false,
      pickupDateMenu: false
    };
  },
  watch: {
    allowNextStep(newVal) {
      // If this step can continue, the the next step should be marked as editable
      this.updateStepContinue(newVal);
    },
    valid(newVal) {
      if (newVal && this.validLocation && this.currentStep === 1) {
        this.updateStepContinue(true);
        this.nextStep();
      }
    }
  },
  mounted() {
    // Same as watcher
    this.updateStepContinue(this.allowNextStep);
  }
};
</script>
