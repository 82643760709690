<template>
  <div class="pb-0">
    <v-row no-gutters>
      <v-col v-if="hasServiceInfo && !locationError" cols="12">
        <v-alert
          id="location-serviceable"
          dark
          dense
          type="success"
          color="green"
        >
          Great news, we service your location in
          <b> {{ site.city }} {{ site.state.short_name }} </b>!
        </v-alert>
      </v-col>

      <v-col cols="12" v-if="locationError">
        <v-alert
          :id="hasServiceInfo ? 'location-imprecise' : 'location-unserviceable'"
          :type="hasServiceInfo ? 'info' : 'error'"
          text
        >
          <span v-html="locationError"></span>
          <v-btn
            v-if="hasServiceInfo && !site.overrideGeo && userAccess > 5"
            @click="site.overrideGeo = !site.overrideGeo"
            small
            tile
            color="action"
            class="white--text float-right"
            >Manually Select Location</v-btn
          >
        </v-alert>
      </v-col>

      <v-col :cols="12">
        <gmap-autocomplete
          v-on:place_changed="updatePlaceData"
          :selectFirstOnEnter="true"
        >
          <template v-slot:default="slotProps">
            <v-text-field
              :disabled="site.overrideGeo"
              @click:clear="resetState"
              :clearable="userAccess > 0"
              prepend-inner-icon="mdi-map"
              :placeholder="
                stepObject.config.deliveryAddressTitle ||
                  'Enter Service Address'
              "
              :hint="
                stepObject.config.deliveryAddressSubtitle ||
                  'Enter service address to confirm availability and to get an accurate quote.'
              "
              persistent-hint
              ref="input"
              :rules="$validationRules.required"
              v-model="address"
              v-on:listeners="slotProps.listeners"
              v-on:attrs="slotProps.attrs"
              @input="clearPlaceData"
            >
            </v-text-field>
          </template>
        </gmap-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { createHelpers } from "vuex-map-fields";
import { getGoogleMapsAPI } from "gmap-vue";

const { mapFields } = createHelpers({
  getterType: "quotes/getField",
  mutationType: "quotes/updateField"
});

import { createNamespacedHelpers } from "vuex";
import {
  CLEAR_PLACE_DATA,
  RESET_STATE
} from "@/store/modules/quotes/mutation-types";
const {
  mapGetters,
  mapActions,
  mapState,
  mapMutations
} = createNamespacedHelpers("quotes");

/**
 * @typedef {object} $validationRules
 * @typedef {string} address
 * @typedef {object} site
 */
export default {
  name: "Location",
  emits: ["location-status"],
  props: ["stepObject"],
  computed: {
    ...mapState(["baseData"]),
    ...mapFields({
      address: "site.formatted_address",
      lat: "site.latitude",
      lng: "site.longitude",
      site: "site",
      customer: "quote.customer"
    }),
    ...mapGetters([
      "hasServiceInfo",
      "locationError",
      "validLocation",
      "userAccess"
    ]),
    google: getGoogleMapsAPI,
    allowNextStep() {
      return this.valid && this.validLocation;
    }
  },
  watch: {
    validLocation(newVal) {
      this.$emit("location-status", newVal);
      if (newVal) {
        document.activeElement.blur();
      }
    },
    site: {
      deep: true,
      handler(newVal) {
        // If we're overriding lat/long, and no customer exists, update the customer with the site info
        if (this.site.overrideGeo && !this.customer.id) {
          this.customer.street = `${newVal.street_number} ${newVal.street}`;
          this.customer.city = newVal.city;
          // Make sure all site fields are set before setting the formatted address
          if (!["street", "city"].filter(v => !newVal[v]).length) {
            this.site.formatted_address = `${
              newVal.street_number ? newVal.street_number + " " : ""
            }${newVal.street}, ${newVal.city}, ${newVal.state.name} ${
              newVal.postal_code.code
            }`;
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(["setPlaceData"]),
    ...mapMutations({
      clearPlaceData: CLEAR_PLACE_DATA,
      resetState: RESET_STATE
    }),
    updatePlaceData(place) {
      this.setPlaceData({ place });
      this.$emit("location-status", this.validLocation);
    },
    updateMarker(marker) {
      this.site.latitude = marker.latLng.lat();
      this.site.longitude = marker.latLng.lng();
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped></style>
