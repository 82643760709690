<template>
  <div class="quote-tool-step-content">
    <v-form v-model="valid" ref="form" @submit.prevent>
      <v-row>
        <template>
          <v-col cols="12" md="12">
            <v-menu
              v-if="$vuetify.breakpoint.mdAndUp"
              v-model="dateMenu"
              :close-on-content-click="true"
              min-width="auto"
              :nudge-right="30"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="preferred-dropoff-date"
                  :value="
                    getFormattedDate('quote.service.preferredDropoffDate')
                  "
                  label="Preferred Drop-Off Date (max 60 days in the future)"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="
                    $validationRules.required.concat([
                      v =>
                        v && allowedDates(v)
                          ? true
                          : 'This date is not allowed.'
                    ])
                  "
                  :persistent-hint="userAccess === 0"
                  :hint="
                    userAccess === 0
                      ? 'Your preferred date is subject to availability. Should you decide to place an order, we will contact you if your preferred date is unavailable.'
                      : ''
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="preferredDropoffDate"
                @input="dateMenu = false"
                :min="dateRange.today"
                :max="dateRange.sixtyDays"
                show-current
                :no-title="userAccess === 0"
                :allowed-dates="allowedDates"
                :events="getEvent"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              v-else
              prepend-icon="mdi-calendar"
              v-model="preferredDropoffDate"
              label="Preferred Dropoff Date (max 60 days in the future)"
              type="date"
              required
              :min="dateRange.today"
              :max="dateRange.sixtyDays"
              :rules="
                $validationRules.required.concat([
                  v =>
                    v && allowedDates(v) ? true : 'This date is not allowed.'
                ])
              "
              :persistent-hint="userAccess === 0"
              :hint="
                userAccess === 0
                  ? 'Your preferred date is subject to availability. Should you decide to place an order, we will contact you if your preferred date is unavailable.'
                  : ''
              "
            />
            <v-alert
              outlined
              dense
              v-if="userAccess > 0 && getEvent(preferredDropoffDate)"
              type="error"
              >The date selected would not be allowed based on the haulers date
              rules.</v-alert
            >
          </v-col>
          <v-col>
            <v-textarea
              id="quote-request-notes"
              v-if="userAccess === 0"
              prepend-icon="mdi-note-text"
              label="Additional Comments & Questions"
              v-model="customerNotes"
            ></v-textarea>
            <div v-else class="mt-15" style="min-height:100px;"></div>
          </v-col>
        </template>
      </v-row>
    </v-form>
    <StepControls
      :next-step-enabled="valid"
      :nextStep="submit"
      :previousStep="previousStep"
    />
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import { UPDATE_STEP_METADATA } from "@/store/modules/quotes/mutation-types";
import StepControls from "../StepControls";
// import RentalPeriodSummary from "@/components/quote-tool/steps/components/date/RentalPeriodSummary";

/**
 * @typedef {object} $validationRules
 * @typedef {string} preferredDropoffDate
 * @typedef {string} serviceType
 * @typedef {boolean} preschedulePickup
 * @typedef {boolean} scheduleRecurringActivity
 * @typedef {string} pickupDate
 * @typedef {string} workOrderNotes
 * @typedef {string} serviceNotes
 * @typedef {string} siteNotes
 * @typedef {string} poNumber
 * @typedef {string} customerNotes
 * @typedef {string} serviceDate
 * @typedef {number} dre
 * @typedef {function} getFormattedDate
 * @typedef {object} recurring
 */
export default {
  name: "DateStep",
  props: ["nextStep", "previousStep", "stepKey"],
  components: {
    StepControls
  },
  computed: {
    ...mapState("quotes", ["isService", "currentStep", "baseData"]),
    ...mapGetters("quotes", ["getFormattedDate", "getIsHaulerDateAllowed"]),
    ...mapGetters("baseData", ["work_order_types"]),
    ...mapFields("quotes", {
      preferredDropoffDate: "quote.service.preferredDropoffDate",
      serviceDate: "workOrders.delivery.service_date",
      serviceType: "quote.service.service_type",
      dre: "quote.service.rental_period_days",
      preschedulePickup: "quote.service.preschedule_pickup",
      scheduleRecurringActivity: "quote.service.schedule_recurring_activity",
      pickupDate: "workOrders.final_pickup.service_date",
      pickupStatus: "workOrders.final_pickup.status_id",
      pickupTime: "workOrders.final_pickup.time_range",
      pickupTimeStart: "workOrders.final_pickup.time_window_start",
      pickupTimeEnd: "workOrders.final_pickup.time_window_end",
      workOrderNotes: "workOrders.delivery.notes",
      serviceNotes: "quote.service.notes",
      siteNotes: "site.notes",
      lat: "site.latitude",
      lng: "site.longitude",
      address: "site.formatted_address",
      poNumber: "quote.service.po_number",
      customerNotes: "quote.service.customer_notes",
      serviceId: "quote.service.id",
      recurringStartDate: "workOrders.recurring.start_date",
      recurringEndDate: "workOrders.recurring.end_date",
      recurring: "workOrders.recurring",
      scheduledActivities: "recurringWorkOrders",
      workOrders: "workOrders",
      timeRange: "workOrders.recurring.time_range",
      timeWindowStart: "workOrders.recurring.time_window_start",
      timeWindowEnd: "workOrders.recurring.time_window_end",
      recurringType: "workOrders.recurring.type_id",
      disableAutomaticDRE: "quote.service.disable_automatic_dre"
    }),
    ...mapFields("quotes", ["searchResults"]),
    dateRange() {
      const min = this.$date();
      return {
        today: min.format("YYYY-MM-DD"),
        sixMonths: min.add(6, "month").format("YYYY-MM-DD"),
        sixtyDays: min.add(60, "day").format("YYYY-MM-DD")
      };
    },
    rentalPeriodStart() {
      return this.$date(this.preferredDropoffDate);
    },
    rentalPeriodEnd() {
      return this.rentalPeriodStart.add(this.dre - 1, "day");
    },
    billingStart() {
      return this.rentalPeriodStart.add(this.dre, "day");
    },
    isLiveLoad() {
      return this.serviceType === "live_load";
    },
    canContinue() {
      return this.valid;
    }
  },
  data() {
    return {
      userAccess: 0,
      valid: false,
      dateMenu: false,
      pickupDateMenu: false,
      recurringStartDateMenu: false,
      recurringEndDateMenu: false
    };
  },
  methods: {
    ...mapMutations("quotes", [UPDATE_STEP_METADATA]),
    ...mapActions("quotes", ["quoteDupeCheck"]),
    ...mapActions("baseData", ["getWorkOrderTypes"]),
    updateAllActivities(updates) {
      this.scheduledActivities = [
        ...this.scheduledActivities.map(activity =>
          Object.assign(activity, updates)
        )
      ];
    },

    isAllowedRecurringEndDate(date) {
      const startDate = this.$date(this.recurring.start_date);
      date = this.$date(date);

      if (this.recurring.date_cadence === "Weekly") {
        // If date is within 6 days of start date, don't allow it
        if (!date.isSameOrAfter(startDate.clone().add(6, "day"))) {
          return false;
        }

        // Check if date falls on the same weekday as recurringStartDate
        return startDate.day() === date.day();
      }

      if (this.recurring.date_cadence === "Bi-Weekly") {
        // If date is within 13 days of start date, don't allow it
        if (!date.isSameOrAfter(startDate.clone().add(13, "day"))) {
          return false;
        }

        // If the difference between date and startDate is not divisible by 2, don't allow it
        if (date.diff(startDate, "day") % 2 !== 0) {
          return false;
        }

        // Check if date falls on the same weekday as recurringStartDate
        return startDate.day() === date.day();
      }

      if (this.recurring.date_cadence === "Monthly") {
        // If date is within 1 month of start date, don't allow it
        if (!date.isSameOrAfter(startDate.clone().add(1, "month"))) {
          return false;
        }

        // Check if date falls on the same day of the month as recurringStartDate
        return startDate.date() === date.date();
      }

      if (this.recurring.date_cadence === "Bi-Monthly") {
        // If date is within 2 months of start date, don't allow it
        if (!date.isSameOrAfter(startDate.clone().add(2, "month"))) {
          return false;
        }

        // If the difference between date and startDate is not divisible by 2, don't allow it
        if (date.diff(startDate, "month") % 2 !== 0) {
          return false;
        }

        // Check if date falls on the same day of the month as recurringStartDate
        return startDate.date() === date.date();
      }

      return true;
    },
    getEvent(val) {
      if (!val) return false;
      if (this.userAccess < 1) return false;

      let date = this.$date(val);

      return !this.getIsHaulerDateAllowed(date) &&
        date.isSameOrAfter(this.$date())
        ? "red"
        : false;
    },
    submit() {
      if (!this.searchResults.quotes.doneDupeChecking && this.shouldDupeCheck) {
        this.searchResults.quotes.doneDupeChecking = true;
        return;
      }
      if (this.$refs.form.validate() && this.canContinue) {
        this.nextStep();
      }
    },
    allowedDates(val, forceCheck = false) {
      if (this.userAccess > 0 && !forceCheck) {
        return true;
      }

      let date = this.$date(val);

      return this.getIsHaulerDateAllowed(date);
    }
  },
  mounted() {
    if (this.userAccess > 0) {
      this.getWorkOrderTypes();
    }

    this[UPDATE_STEP_METADATA]({
      component: this.$options.name,
      canContinue: this.canContinue
    });
  },
  watch: {
    scheduleRecurringActivity(newVal) {
      if (newVal) {
        this.disableAutomaticDRE = true;
      } else {
        this.disableAutomaticDRE = false;
      }
    },
    currentStep(newVal) {
      if (newVal === 2 && this.shouldDupeCheck) {
        this.quoteDupeCheck();
      } else if (newVal === 2 && this.serviceId) {
        this.$refs.form.validate();
      }
    },
    canContinue(newVal) {
      this[UPDATE_STEP_METADATA]({
        component: this.$options.name,
        canContinue: newVal
      });
    },
    pickupDate(val) {
      if (val) {
        this.pickupStatus = 2;
      }
    },
    preschedulePickup(val) {
      if (!val) {
        this.pickupDate = "";
        this.pickupStatus = 1;
        this.pickupTimeStart = "";
        this.pickupTimeEnd = "";
        this.pickupTime = "any";
      }
    },
    recurringEndDate() {
      if (this.recurringEndDate) {
        this.scheduledActivities = this.parsedScheduledActivities.map(
          activity => {
            const existingActivity = this.scheduledActivities.find(
              ea => ea.original_service_date === activity.original_service_date
            );
            return existingActivity || activity;
          }
        );
      } else {
        this.scheduledActivities = [];
      }
    },
    recurringStartDate() {
      if (
        this.recurringStartDate &&
        this.recurringEndDate &&
        this.isAllowedRecurringEndDate(this.recurringEndDate)
      ) {
        this.scheduledActivities = this.parsedScheduledActivities;
      } else {
        this.recurringEndDate = "";
      }
    },

    timeRange(newVal) {
      this.updateAllActivities({ time_range: newVal });
    },
    timeWindowStart(newVal) {
      this.updateAllActivities({ time_window_start: newVal });
    },
    timeWindowEnd(newVal) {
      this.updateAllActivities({ time_window_end: newVal });
    },
    recurringType(newVal) {
      this.updateAllActivities({ type_id: newVal });
    }
  }
};
</script>
