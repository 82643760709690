import env from "@/env";

export const getAnetEndpoint = () => {
  const selectedEndpoint = env.api.url;

  const productionEndpoint = "https://crm.rolloff360.com/api/v1";

  const endpoints = {
    production: "https://js.authorize.net/v1/Accept.js",
    development: "https://jstest.authorize.net/v1/Accept.js"
  };

  if (selectedEndpoint.includes(productionEndpoint)) {
    return endpoints.production;
  }

  return endpoints.development;
};
