import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { getBaseUrl } from "@/utils";
import env from "@/env";
axios.defaults.baseURL = getBaseUrl();
axios.defaults.timeout = 300000;

const axiosInstance = axios.create({ ...env.config.axios });

axiosInstance.interceptors.request.use(
  config => {
    return config;
  },
  error => Promise.reject(error)
);

Vue.use(VueAxios, axiosInstance);

export default {
  root: axios.defaults.baseURL
};
