<template>
  <div>
    <v-divider class="mb-3 mt-3"></v-divider>
    <v-container
      fluid
      class="d-flex flex-column flex-md-row justify-space-between"
    >
      <template v-if="previousStep">
        <v-btn
          outlined
          color="brand"
          @click="previousStep"
          :id="`step-${currentStep}`"
          class="mb-2 mb-md-0 text-capitalize"
        >
          <v-icon left>mdi-chevron-left</v-icon> {{ previousStepTitle }}
        </v-btn>
      </template>
      <v-spacer v-if="!$slots.default && !actions.length"></v-spacer>
      <v-btn
        v-if="nextStep"
        :disabled="!nextStepEnabled"
        :tile="userAccess > 0"
        outlined
        color="orange"
        @click="nextStep"
        :id="`step-${currentStep}`"
        class="mb-2 mb-md-0 text-capitalize"
      >
        {{ nextStepTitle }} <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
      <template v-if="$slots.default || actions.length">
        <v-spacer></v-spacer>
        <slot></slot>
        <template v-if="actions.length">
          <v-btn
            :color="action.color || 'primary'"
            :key="action.title"
            @click="action.action"
            v-for="action in actions"
            >{{ action.title }}</v-btn
          >
        </template>
      </template>
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    actions: {
      type: Array,
      default: () => []
    },
    nextStep: {
      type: Function
    },
    nextStepTitle: {
      type: String,
      default: "Continue"
    },
    nextStepEnabled: {
      type: Boolean,
      default: true
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    previousStep: {
      type: Function
    },
    previousStepTitle: {
      type: String,
      default: "Back"
    }
  },
  computed: {
    ...mapGetters("quotes", ["userAccess", "customerSiteData"]),
    ...mapState("quotes", ["currentStep", "quote"])
  },
  watch: {
    currentStep() {
      if (["xs", "sm", "md"].includes(this.$vuetify.breakpoint.name)) {
        this.$vuetify.goTo(".v-stepper", {});
      }
    }
  }
};
</script>
