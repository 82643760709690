import { VueMaskDirective } from "v-mask";

const Masks = {
  phone: "(###) ###-####"
};

const Mask = {
  install(Vue) {
    if (this.installed) return;
    Vue.directive("mask", VueMaskDirective);
    Vue.prototype.$masks = Masks;
    this.installed = true;
  }
};

export default Mask;
