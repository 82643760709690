import { getField } from "vuex-map-fields";
import dayjs from "@/plugins/dayjs";

export default {
  getField,
  isInitialized(state) {
    return (
      state.baseData.containerTypes && state.baseData.containerTypes.length > 0
    );
  },
  haulerRules(state, getters) {
    return state.baseData.hauler_rules.filter(
      rule => rule.hauler_id === getters.hauler
    );
  },
  blackoutDates(state, getters) {
    return getters.haulerRules.filter(rule => rule.start_date && rule.end_date);
  },
  weekdayHaulerRules(state, getters) {
    return getters.haulerRules.filter(
      rule =>
        (rule.weekday || rule.weekday === 0) &&
        (rule.cutoff_hour || rule.cutoff_hour === 0) &&
        (rule.next_available_weekday || rule.next_available_weekday === 0)
    );
  },
  haulerGlobalCutoffTime(state, getters) {
    return (
      getters.haulerRules.find(
        rule =>
          !rule.weekday &&
          (rule.cutoff_hour || rule.cutoff_hour === 0) &&
          !rule.next_available_weekday
      ) || { cutoff_hour: 14 }
    ).cutoff_hour;
  },
  getIsHaulerDateAllowed(state, getters) {
    /**
     * Return if a specified date is allowed by the current hauler
     * @param {string} date - The date to check
     */
    return date => {
      let now = dayjs();

      if (now.hour() >= getters.haulerGlobalCutoffTime && date.isTomorrow()) {
        return false;
      }

      let blackoutDate = getters.blackoutDates.find(
        rule =>
          date.isSameOrBefore(dayjs(rule.end_date).hour(24)) &&
          date.isSameOrAfter(dayjs(rule.start_date).hour(0))
      );

      let nowWeekdayRule = getters.weekdayHaulerRules.find(
        rule => now.weekday() === rule.weekday && now.hour() >= rule.cutoff_hour
      );

      let weekdayRule = getters.weekdayHaulerRules.find(
        rule => date.weekday() === rule.weekday && !rule.is_available
      );

      if (nowWeekdayRule) {
        let nextAvailableDate = now.add(1, "day");

        while (
          nextAvailableDate.weekday() !== nowWeekdayRule.next_available_weekday
        ) {
          nextAvailableDate = nextAvailableDate.add(1, "day");
        }

        if (date.dayOfYear() < nextAvailableDate.dayOfYear()) {
          return false;
        }
      }

      return !blackoutDate && date.isAfter(now) && !weekdayRule;
    };
  },
  getFormattedDate(state, getters) {
    /**
     * Return a formatted date by store string
     * @param {string} path - The path to the state in dot notation
     * @param {string} format (optional) - The date format to use
     */
    return (path, format = "MM/DD/YYYY") =>
      getters["getField"](path)
        ? dayjs(getters["getField"](path)).format(format)
        : "";
  },
  customerData(state, getters) {
    if (!state.site.formatted_address || getters.customerSiteData === false)
      return false;
    return {
      id: state.quote.customer.id || state.quote.customer.pending_id || null,
      pending_id: state.quote.customer.pending_id || null,
      first_name: state.quote.customer.firstName,
      last_name: state.quote.customer.lastName,
      company_name: state.quote.customer.companyName,
      phone: state.quote.customer.phoneNumber,
      phone_extension: state.quote.customer.phone_extension,
      email: `${state.quote.customer.emailAddress}`.trim(),
      street: state.quote.customer.street,
      city: state.quote.customer.city,
      short_state: state.quote.customer.state.short_name,
      state_id: state.quote.customer.state.id,
      postal_code_display: state.quote.customer.postal_code.code,
      customer_type_id: state.quote.customer.customer_type_id,
      customer_source_id: state.quote.customer.customer_source_id,
      charge_at_sale: state.quote.customer.charge_at_sale,
      notes: state.quote.customer.notes,
      final_pickup_billing: state.quote.customer.final_pickup_billing,
      customer_sites: [getters.customerSiteData],
      billing_term_id: state.quote.customer.billing_term_id
    };
  },
  customerSiteData(state, getters) {
    if (!state.site.formatted_address) return false;
    return {
      id: state.site.id || state.site.pending_id || null,
      pending_id: state.site.pending_id || null,
      latitude: state.site.latitude,
      longitude: state.site.longitude,
      formatted_address: `${
        state.site.street_number ? state.site.street_number + " " : ""
      }${state.site.street}, ${state.site.city}, ${
        state.site.state.short_name
      } ${state.site.postal_code.code}, USA`,
      street_number: state.site.street_number,
      street: state.site.street,
      city: state.site.city,
      state: state.site.state.name,
      state_id: state.site.state.id,
      short_state: state.site.state.short_name,
      postal_code: state.site.postal_code.code,
      site_title: state.site.site_title,
      location_id: getters.serviceInfo.location_id,
      notes: state.site.notes,
      services: [getters.serviceData],
      customer_contacts: [
        Object.assign({}, state.siteContact, {
          id: state.siteContact.id || state.siteContact.pending_id || 0
        })
      ]
    };
  },
  paymentData(state) {
    return {
      dataValue: state.quote.payment.dataValue,
      dataDescriptor: state.quote.payment.dataDescriptor,
      first_name:
        state.quote.payment.first_name || state.quote.customer.first_name || "",
      last_name:
        state.quote.payment.last_name || state.quote.customer.last_name || "",
      street: state.quote.payment.street || state.quote.customer.street || "",
      city: state.quote.payment.city || state.quote.customer.city || "",
      state:
        state.quote.payment.state ||
        state.quote.customer.state.short_name ||
        "",
      postal_code_display:
        state.quote.payment.postal_code_display ||
        state.quote.customer.postal_code.code ||
        "",
      phone:
        state.quote.payment.phone || state.quote.customer.phoneNumber || "",
      email:
        state.quote.payment.email || state.quote.customer.emailAddress || "",
      preferred_delivery_date:
        state.quote.payment.preferred_delivery_date ||
        state.quote.service.preferredDropoffDate ||
        "",
      work_order_note:
          (state.quote.service.work_order_note || "" ) +
          (state.quote.service.placement_option ?
              " (Placement: " +  state.quote.service.placement_option + ") ":
              "") +
          (state.quote.service.placement_option_other ?
              "(" + state.quote.service.placement_option_other + ")":
              ""),
      time_range: state.quote.payment.time_range || "",
      service_id: 0
    };
  },
  priceModifiersData(state) {
    const modifiers = [];

    if (state.quote.service.rentalPeriodObject?.id) {
      modifiers.push({
        id: state.quote.service.rentalPeriodObject?.id,
        type: "rental_period_days",
        value: state.quote.service.rentalPeriodObject?.value,
        price: state.quote.service.rentalPeriodObject?.price
      });
    }

    if (state.quote.service.weightLimitObject?.id) {
      modifiers.push({
        id: state.quote.service.weightLimitObject?.id,
        type: "weight_allowance",
        value: state.quote.service.weightLimitObject?.value,
        price: state.quote.service.weightLimitObject?.price
      });
    }

    return modifiers;
  },
  serviceData(state, getters) {
    if (!getters.serviceInfo.success) return {};
    const output = {
      id: state.quote.service.id,
      container_type_id: state.quote.service.containerType,
      debris_type_id: state.quote.service.debrisType,
      service_date: state.quote.service.preferredDropoffDate,
      hauler_id: getters.hauler,
      catalog_id: getters.determinedPriceGroup.catalog_id,
      location_id: getters.serviceInfo.location_id,
      term_id: getters.serviceInfo.terms.id,
      daily_rental_rate: getters.terms.daily_rental_rate,
      rental_period_days: getters.terms.rental_period_days,
      overweight_rate: getters.terms.overweight_rate,
      mattresses_rate: getters.terms.mattresses_rate,
      couches_rate: getters.terms.couches_rate,
      weight_allowance: state.quote.service.weight_allowance,
      tires_rate: getters.terms.tires_rate,
      live_load_wait_rate: getters.terms.live_load_wait_rate,
      service_attempt_rate: getters.terms.service_attempt_rate,
      relocate_from_previous_site_rate:
        getters.terms.relocate_from_previous_site_rate,
      relocate_on_site_rate: getters.terms.relocate_on_site_rate,
      email_comments: state.quote.service.email_comments,
      po_number: state.quote.service.po_number,
      referral_notes: state.quote.service.referral_notes,
      work_orders: getters.workOrderData,
      number_of_days: getters.dateSpan,
      disable_automatic_dre: state.quote.service.disable_automatic_dre,
      project_type_id: state.quote.service.projectType,
      payment: getters.paymentData,
      price_modifiers: getters.priceModifiersData,
      quote_tool_version: process.env.VUE_APP_QUOTE_TOOL_VERSION,
      quote_tool_url: window.location.href.substring(0, 254),
      placement_option: state.quote.service.placement_option,
      placement_option_other: state.quote.service.placement_option_other
    };

    if (
      !!state.quote.service.override_price ||
      state.quote.service.override_price === 0
    ) {
      output.override_price = state.quote.service.override_price;
    }
    if (
      !!state.quote.service.override_exchange_price ||
      state.quote.service.override_exchange_price === 0
    ) {
      output.override_exchange_price =
        state.quote.service.override_exchange_price;
    }

    if (state.quote.service.override_reason) {
      output.override_description =
        state.quote.service.override_reason === "Other"
          ? state.quote.service.override_description
          : state.quote.service.override_reason;
    }

    if (getters.userAccess > 0) {
      output.notes = state.quote.service.notes;
    } else {
      output.customer_notes = state.quote.service.customer_notes;
    }
    return output;
  },
  workOrderData(state) {
    return state.quote.service.service_type === "live_load"
      ? [
          Object.assign({}, state.workOrders.delivery, {
            type_id: 5
          })
        ]
      : [
          state.workOrders.delivery,
          state.workOrders.final_pickup,
          ...state.recurringWorkOrders.filter(wo => !wo.skip)
        ];
  },
  dateSpan(state) {
    var date1 = new Date(state.workOrders.delivery.service_date);
    var date2 = new Date(state.workOrders.final_pickup.service_date);
    var diffInTime = date2.getTime() - date1.getTime();
    var diffInDays = diffInTime / (1000 * 3600 * 24);
    return diffInDays;
  },
  payload: (state, getters) => {
    return getters.customerData === false
      ? false
      : {
          customer: getters.customerData,
          is_service: state.isService,
          send_quote: state.sendQuote
        };
  },
  haulers: state => state.baseData.haulers,
  hauler: (state, getters) => {
    // If a hauler was manually selected, return it
    if (state.selectedHauler && state.selectedHauler) {
      return state.selectedHauler;
    }
    if (!state.quote.service.debrisType || !state.quote.service.containerType)
      return false;
    let hauler = getters.priceGroups.find(
      priceGroup =>
        priceGroup.debris_type_id === state.quote.service.debrisType &&
        priceGroup.container_type_id === state.quote.service.containerType &&
        priceGroup.hauler_id
    );
    return hauler ? hauler.hauler_id : false;
  },
  // Return a list of haulers that are being used in price groups items
  availableHaulers(state, getters) {
    const utilizedIds = getters.priceGroupsFormatted
      .map(item => item.haulers)
      .flat()
      .filter((item, i, self) => {
        return self.indexOf(item) === i;
      });

    return getters.haulers.filter(item => {
      return utilizedIds.indexOf(item.id) !== -1;
    });
  },
  serviceInfo: state => {
    if (state.serviceInfo.success) {
      return state.serviceInfo;
    }
    if (state.serviceInfoByCity.success) {
      return state.serviceInfoByCity;
    }
    return {};
  },
  termsOriginal(state, getters) {
    return getters.serviceInfo.terms || {};
  },
  /**
   * Returns the terms specific to this service (include overrides from service object)
   * @param state
   * @param getters
   * @returns {{}}
   */
  terms(state, getters) {
    let serviceTermsFields = [
      "daily_rental_rate",
      "live_load_wait_rate",
      "mattresses_rate",
      "couches_rate",
      "overweight_rate",
      "relocate_from_previous_site_rate",
      "relocate_on_site_rate",
      "rental_period_days",
      "service_attempt_rate",
      "tires_rate",
      "original_rental_period_days",
      "original_overweight_rate"
    ];

    // Include all original terms in object, so we have a complete terms object
    const terms = { ...getters.termsOriginal };
    // Use values from the service object (if they are set) instead of the original
    Object.entries(state.quote.service)
      // Don't include fields from service that aren't in serviceTermsFields
      .filter(([key]) => serviceTermsFields.indexOf(key) !== -1)
      .map(([key, val]) => {
        if (!!val || val === 0) {
          terms[key] = val;
        }
      });
    return terms;
  },
  catalogDetails: (state, getters) =>
    getters.serviceInfo.success ? getters.serviceInfo.catalogDetails : {},
  priceGroups: (state, getters) => Object.values(getters.catalogDetails).flat(),
  priceGroupsFormatted: (state, getters) => {
    if (
      getters.serviceInfo.priceGroupsFormatted &&
      !getters.serviceInfo.priceGroupsFormatted[0].price
    ) {
      return getters.priceGroups;
    }
    return getters.serviceInfo.priceGroupsFormatted
      ? getters.serviceInfo.priceGroupsFormatted
      : [];
  },
  selectedPriceGroup(state, getters) {
    if (!state.serviceSelectionId || !state.serviceSelectionId.length)
      return {};
    return (
      getters.priceGroupsFormatted.find(item => {
        return item.id === state.serviceSelectionId;
      }) || {}
    );
  },
  basePrice(state, getters) {
    return (
      getters.selectedPriceGroup?.price ||
      getters.determinedPriceGroup?.price ||
      0
    );
  },
  currentPrice(state, getters) {
    const basePrice = getters.basePrice;

    if (getters.priceModifiersData.length) {
      return getters.priceModifiersData.reduce((acc, item) => {
        return acc + parseFloat(item.price);
      }, basePrice);
    }

    return basePrice;
  },
  hasServiceInfo: (state, getters) => getters.serviceInfo.success,
  projectTypes: state =>
    state.baseData.projectTypes ? state.baseData.projectTypes : [],
  debrisTypes: (state, getters) => {
    // Get all allowed debris type ids from selected project type
    const allowedDebrisTypeIds = state.quote.service.projectType
      ? getters.projectTypes
          .find(
            projectType => projectType.id === state.quote.service.projectType
          )
          .debris_types.map(debrisType => debrisType.id)
      : (state.baseData.debrisTypes || []).map(debrisType => debrisType.id);

    return state.baseData.debrisTypes
      ? state.baseData.debrisTypes.filter(
          debrisType =>
            getters.priceGroupsFormatted.filter(
              priceGroup => priceGroup.debris_type_id == debrisType.id
            ).length && allowedDebrisTypeIds.includes(debrisType.id)
        )
      : [];
  },
  availableDebrisTypes: (state, getters) => {
    return state.baseData.debrisTypes
      ? state.baseData.debrisTypes.filter(
          debrisType =>
            getters.priceGroupsFormatted.filter(
              priceGroup => priceGroup.debris_type_id === debrisType.id
            ).length
        )
      : [];
  },
  serviceSelectionPriceModifiers: (state, getters) => {
    // Find any price group that matches the determined price group
    // and check if there are any price modifiers
    const determinedPriceGroup = getters.determinedPriceGroup;

    if (!determinedPriceGroup) return [];

    // Find all price groups that match the determined price group
    const priceGroups = getters.priceGroups.filter(
      priceGroup =>
        priceGroup.slug === determinedPriceGroup.slug &&
        priceGroup.price_modifiers?.length
    );

    // Flatten and return the price modifiers
    return priceGroups.map(priceGroup => priceGroup.price_modifiers).flat();
  },
  containerTypes(state, getters) {
    if (!state.baseData.containerTypes) return [];
    return state.baseData.containerTypes
      .map(container => {
        return {
          ...container,
          debris_types: state.baseData.debrisTypes,
          price_groups: getters.priceGroupsFormatted
            .filter(priceGroup => {
              const debrisType = !state.quote.service.debrisType
                ? true
                : priceGroup.debris_type_id === state.quote.service.debrisType;
              return (
                priceGroup.container_type_id === container.id && debrisType
              );
            })
            .map(priceGroup => {
              return {
                ...priceGroup,
                debris_type: !state.baseData.debrisTypes
                  ? undefined
                  : state.baseData.debrisTypes.find(
                      dt => dt.id === priceGroup.debris_type_id
                    )
              };
            })
        };
      })
      .filter(container => {
        return container.price_groups.length;

        // if (getters.userAccess > 0) {
        //   // These users get everything with a set price
        // }
        // // Use non-formatted price groups to see what types are available
        // return getters.priceGroups.filter(priceGroup => {
        //   const debrisType = !state.quote.service.debrisType
        //     ? true
        //     : priceGroup.debris_type_id === state.quote.service.debrisType;
        //   return priceGroup.container_type_id === container.id && debrisType;
        // }).length;
      });
  },
  siteContacts(state) {
    return state.siteContacts.filter(
      item => item.customer_id === state.quote.customer.id
    );
  },
  eligiblePriceGroups: (state, getters) =>
    (getters.priceGroups || []).filter(
      priceGroup =>
        priceGroup.container_type_id === state.quote.service.containerType &&
        priceGroup.debris_type_id === state.quote.service.debrisType
    ),
  determinedPriceGroup: (state, getters) => {
    if (!getters.eligiblePriceGroups) return {};

    return (
      getters.eligiblePriceGroups.find(
        priceGroup => priceGroup.hauler_id === (getters.hauler || 1)
      ) ||
      getters.eligiblePriceGroups[0] ||
      {}
    );
  },
  locationError: (state, getters) => {
    let impreciseError =
      getters.userAccess > 0
        ? "<b>We service your area!</b> Before proceeding, you must provide an exact address, including the <b>street number</b>."
        : `We service your area; however, you will need to provide the full street address to process your request through our self-service portal. If you don’t know the street address, <a class="blue--text text-decoration-underline font-weight-bold" href='https://nextdaydumpsters.com/quote-request/?ref=imprecise-location'>click here</a>.`;

    if (state.serviceInfoByCity && state.serviceInfoByCity.message) {
      switch (state.serviceInfoByCity.message) {
        case "Area is not wholly serviced by one price group. It is necessary to provide a more specific address.":
          return `We service certain areas in <b>${state.site.city}</b>, but we need a precise location to know for sure. <b>Please include your zip code.</b>`;
        case "No service in this area":
        case "This city state not found in the postal code database":
          return "It looks like we don’t service your location. If you think this is wrong, please call us at 1-800-954-NEXT.";
      }
    }

    if (state.serviceInfo.message && !state.serviceInfo.success) {
      return "It looks like we don’t service your location. If you think this is wrong, please call us at 1-800-954-NEXT.";
    }

    // It was requested the we display an error message even when we're able to get
    // results using the city and state
    if (state.serviceInfoByCity.success && !state.site.postal_code.code) {
      // return "Please select an exact address with a street address and zip code.";
      return impreciseError;
    }

    if (
      (state.serviceInfoByCity.success || state.serviceInfo.success) &&
      !state.site.street_number &&
      !state.site.overrideGeo &&
      !state.site.id
    ) {
      return impreciseError;
    }

    return false;
  },
  geoLocation(state) {
    return {
      lat: state.site.latitude || null,
      lng: state.site.longitude || null
    };
  },
  searchResultsSite(state) {
    return state.searchResults.geolocation;
  },
  quoteError(state) {
    return state.quote.error;
  },
  validLocation(state, getters) {
    return !getters.locationError && getters.serviceInfo.success;
  },
  validPartialLocation(state, getters) {
    return getters.userAccess > 0 && getters.serviceInfo.success;
  },
  userInfo(state) {
    return state.baseData.userInfo || {};
  },
  userAccess(state, getters) {
    if (state.quote.forceUserAccess) {
      return 0;
    }
    return getters.userInfo.level || 0;
  },
  publicSteps(state, getters) {
    return state.userSteps
      .map((item, i) => {
        const output = {
          ...item
        };

        // If this step exists in state.currentConfig.steps, based on component, set the title and subtitle
        const stepConfig = state.currentConfig.steps.find(
          step => step.component === item.component
        );

        if (
          !state.currentConfig.showProjectTypes &&
          item.component === "ProjectTypeStep"
        ) {
          output.hidden = true;
        }

        if (stepConfig) {
          // Merge the stepConfig with the output
          Object.assign(output, stepConfig);
        }

        // If the subtitle is hidden, set it to an empty string
        if (state.currentConfig.hideStepSubtitles) {
          output.subtitle = "";
        }

        if (item.component === "CustomerStep") {
          output.subtitle = `${
            state.quote.customer.companyName
              ? state.quote.customer.companyName + " - "
              : ""
          } ${state.quote.customer.firstName} ${state.quote.customer.lastName}`;
        }

        if (
          ["DateStep", "CustomerStep"].includes(item.component) &&
          state.quote.purchaseService
        ) {
          output.hidden = true;
          output.cre = true;
        }

        if (item.component === "Review" && !state.quote.purchaseService) {
          output.hidden = true;
        }

        if (item.component === "CustomerStep" && state.quote.purchaseService) {
          output.hidden = true;
        }

        if (item.component === "ProjectTypeStep") {
          // Get project type title
          const projectType = getters.getBaseDataById(
            "projectTypes",
            state.quote.service.projectType
          );

          if (projectType) {
            output.subtitle = projectType.title;
          }
        }
        if (item.component === "LocationStep" && state.site.formatted_address) {
          output.subtitle = state.site.formatted_address;
        }

        if (item.component === "MaterialTypeStep") {
          // Get project type title
          const containerType = getters.getBaseDataById(
            "debrisTypes",
            state.quote.service.debrisType
          );

          if (containerType) {
            output.subtitle = containerType.description || "N/A";
          }
        }

        if (item.component === "ContainerSizeStep" && i > 2) {
          const containerType = getters.getBaseDataById(
            "containerTypes",
            state.quote.service.containerType
          );

          if (containerType) {
            output.subtitle = containerType.description;
          }
        }

        if (item.component === "DateStep") {
          output.subtitle = state.isService
            ? dayjs(state.workOrders.delivery.service_date).format("MM/DD/YYYY")
            : state.quote.service.preferredDropoffDate
            ? dayjs(state.quote.service.preferredDropoffDate).format(
                "MM/DD/YYYY"
              )
            : "";
        }

        if (["CustomerStep", "InitialCustomerStep"].includes(item.component)) {
          if (state.quote.customer.firstName) {
            output.subtitle = `${
              state.quote.customer.companyName
                ? state.quote.customer.companyName + " - "
                : ""
            } ${state.quote.customer.firstName} ${
              state.quote.customer.lastName
            }`;
          }
        }

        if (i > 0) {
          if (state.userSteps[i - 1].canContinue) {
            output.editable = true;
          }
        }

        if (item.component === "FinishStep") {
          // Final step is never editable
          output.editable = false;
        }

        return output;
      })
      .filter(item => {
        if (item.hidden) return false;
        if (item.cre !== undefined) {
          if (item.cre) return false;
        }
        return true;
      });
  },
  steps(state, getters) {
    if (process.env.VUE_APP_QUOTE_TOOL_VERSION == 1) {
      return state.steps
        .map((item, i) => {
          const output = {
            ...item
          };
          if (item.component === "LocationStep") {
            output.subtitle =
              state.site.street_number || state.site.overrideGeo
                ? state.site.formatted_address
                : "";
          }
          if (item.component === "DateStep") {
            output.subtitle = state.isService
              ? dayjs(state.workOrders.delivery.service_date).format(
                  "MM/DD/YYYY"
                )
              : state.quote.service.preferredDropoffDate
              ? dayjs(state.quote.service.preferredDropoffDate).format(
                  "MM/DD/YYYY"
                )
              : "";
          }
          if (item.component === "CustomerStep") {
            output.subtitle = `${
              state.quote.customer.companyName
                ? state.quote.customer.companyName + " - "
                : ""
            } ${state.quote.customer.firstName} ${
              state.quote.customer.lastName
            }`;
          }

          if (getters.userAccess > 0 && i > 0) {
            if (state.steps[i - 1].canContinue) {
              output.editable = true;
            }
          }

          if (item.component === "FinishStep" || !(getters.userAccess > 0)) {
            // Final step is never editable
            output.editable = false;
          }

          if (item.component === "FinishStep" && getters.userAccess > 0) {
            // Hide finish step for CSRs
            output.hidden = true;
          }

          return output;
        })
        .filter(item => {
          if (item.cre !== undefined) {
            if (item.cre && !(getters.userAccess > 0)) return false;
          }
          return true;
        });
    }

    return (getters.userAccess === 0 &&
    process.env.VUE_APP_QUOTE_TOOL_VERSION == 2
      ? state.userSteps
      : state.steps
    )
      .map((item, i) => {
        const output = {
          ...item
        };

        if (getters.userAccess > 0) {
          item.subtitle = "";
        }

        if (item.component === "DateStep" && getters.userAccess === 0) {
          item.hidden = true;
        }

        if (item.component === "CustomerStep") {
          output.subtitle = `${
            state.quote.customer.companyName
              ? state.quote.customer.companyName + " - "
              : ""
          } ${state.quote.customer.firstName} ${state.quote.customer.lastName}`;
        }

        if (
          ["DateStep", "CustomerStep"].includes(item.component) &&
          state.quote.purchaseService &&
          getters.userAccess === 0
        ) {
          output.hidden = true;
          output.cre = true;
        }

        if (
          item.component === "Review" &&
          !state.quote.purchaseService &&
          getters.userAccess === 0
        ) {
          output.hidden = true;
        }

        if (item.component === "Review" && getters.userAccess === 0) {
          output.title = state.quote.purchaseService
            ? "Place Order"
            : "Save Quote";
        }

        if (item.component === "ProjectTypeStep") {
          // Get project type title
          const projectType = getters.getBaseDataById(
            "projectTypes",
            state.quote.service.projectType
          );

          if (projectType) {
            output.subtitle = projectType.title;
          }
        }
        if (item.component === "LocationStep") {
          output.subtitle =
            state.site.street_number || state.site.overrideGeo
              ? state.site.formatted_address
              : "";
        }

        if (item.component === "MaterialTypeStep") {
          // Get project type title
          const containerType = getters.getBaseDataById(
            "debrisTypes",
            state.quote.service.debrisType
          );

          if (containerType) {
            output.subtitle = containerType.description || "N/A";
          }
        }

        if (item.component === "ContainerSizeStep") {
          output.subtitle = state.quote.service.containerType;
          const containerType = getters.getBaseDataById(
            "containerTypes",
            state.quote.service.containerType
          );

          if (containerType) {
            output.subtitle = containerType.description;
          }
        }

        if (item.component === "DateStep") {
          output.subtitle = state.isService
            ? dayjs(state.workOrders.delivery.service_date).format("MM/DD/YYYY")
            : state.quote.service.preferredDropoffDate
            ? dayjs(state.quote.service.preferredDropoffDate).format(
                "MM/DD/YYYY"
              )
            : "";
        }

        if (item.component === "CustomerStep") {
          output.subtitle = `${
            state.quote.customer.companyName
              ? state.quote.customer.companyName + " - "
              : ""
          } ${state.quote.customer.firstName} ${state.quote.customer.lastName}`;
        }

        if (getters.userAccess > 0 && i > 0) {
          if (state.steps[i - 1].canContinue) {
            output.editable = true;
          }
        }

        if (item.component === "FinishStep" || !(getters.userAccess > 0)) {
          // Final step is never editable
          output.editable = false;
        }

        if (item.component === "FinishStep" && getters.userAccess > 0) {
          // Hide finish step for CSRs
          output.hidden = true;
        }

        return output;
      })
      .filter(item => {
        if (item.hidden && getters.userAccess === 0) return false;
        if (item.cre !== undefined) {
          if (item.cre && !(getters.userAccess > 0)) return false;
        }
        return true;
      });
  },
  getBaseDataById: state => (type, id) => {
    return (state.baseData[type] || []).find(item => item.id === id);
  },
  weightLimits(state, getters) {
    if (!getters.determinedPriceGroup?.weight_allowance) return [];
    if (!getters.serviceSelectionPriceModifiers) return [];

    const baseWeightLimit = getters.determinedPriceGroup.weight_allowance;

    if (getters.serviceSelectionPriceModifiers) {
      const modifiers = getters.serviceSelectionPriceModifiers.filter(
        item => item.type === "weight_allowance"
      );

      if (modifiers.length) {
        // Add modifier for base weight limit
        modifiers.push({
          valueChange: "0",
          value: baseWeightLimit,
          price: 0,
          id: 0,
          default: true,
          secondary_term_value: getters.terms.original_overweight_rate,
          secondary_term_field: "overweight_rate"
        });

        return modifiers
          .map(item => {
            let tons = parseFloat(item.value);
            let totalTons = tons;
            const price = parseFloat(item.price);
            const secondaryTermValue = parseFloat(
              item.secondary_term_value || getters.terms.overweight_rate
            );

            if (price < 0 || price > 0) {
              totalTons += baseWeightLimit;
            }

            return {
              ...item,
              secondary_term_value: secondaryTermValue,
              secondary_term_field: "overweight_rate",
              id: item.id,
              value: parseFloat(totalTons),
              valueChange: item.valueChange || parseFloat(tons),
              price: parseFloat(price),
              default: item.default || false,
              subtitle: `Overweight Costs: $${secondaryTermValue}/ton`
            };
          })
          .sort((a, b) => a.value - b.value);
      }
    }

    return [];
  },
  rentalPeriods(state, getters) {
    if (!getters.terms) return [];

    const terms = getters.terms;

    if (!terms) return [];
    const rental_period_days = getters.terms.rental_period_days;

    let {
      // original_rental_period_days: rental_period_days,
      price_modifiers
    } = terms;

    if (!rental_period_days) return [];

    if (!price_modifiers) return [];

    if (!price_modifiers.length) return [];

    const modifiers = [
      ...price_modifiers.filter(item => item.type === "rental_period_days")
    ];

    // Insert one entry in the middle of mods
    modifiers.push({
      type: 'rental_period_days',
      valueChange: "0",
      value: parseFloat(rental_period_days),
      price: 0,
      id: 0,
      default: true
    });

    return modifiers
      .map(item => {
        let days = parseFloat(item.value);
        let totalDays = days;
        const price = parseFloat(item.price);

        if (price < 0 || price > 0) {
          totalDays += rental_period_days;
        }

        return {
          id: item.id,
          value: parseInt(totalDays),
          valueChange: item.valueChange || parseInt(days),
          price: parseFloat(price),
          default: item.default || false
        };
      })
      .sort((a, b) => a.price - b.price);
  },
  hasStepBeenCompleted: state => step => {
    return state.completedSteps.includes(step);
  },
  currentStep(state, getters) {
    if (!getters.publicSteps[state.currentStep - 1]) {
      return false;
    }
    // Return the step that is currently being viewed
    return getters.publicSteps[state.currentStep - 1];
  },
  anetKeys(state) {
    if (!state.serviceInfo || !state.serviceInfo.paymentData) return {};
    return state.serviceInfo.paymentData;
  },
};
