<template>
  <div class="quote-tool-step-content">
    <v-form @submit.prevent="submit" ref="form" v-model="valid">
      <Service />
    </v-form>

    <PublicStepControls
      :nextStep="submit"
      nextStepTitle="Email My Quote"
      :nextStepEnabled="allowNextStep"
    >
      <v-btn @click="purchaseStep" color="action" dark>
        SCHEDULE NOW! <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
    </PublicStepControls>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import { UPDATE_STEP_METADATA } from "@/store/modules/quotes/mutation-types";
import Service from "./components/Service";

import PublicStepControls from "../PublicStepControls.vue";

/**
 * @typedef {int} isService
 * @typedef {bool} validLocation
 * */
export default {
  name: "ServiceStep",
  props: ["nextStep"],
  components: {
    PublicStepControls,
    Service
  },
  computed: {
    ...mapFields("quotes", ["isService", "quote", "loading"]),
    ...mapGetters("quotes", [
      "userAccess",
      "validPartialLocation",
      "validLocation"
    ]),
    allowNextStep() {
      return this.validLocation === true && this.valid;
    }
  },
  methods: {
    purchaseStep() {
      this.quote.purchaseService = true;
      this.allowNextStep && this.nextStep();
    },
    ...mapMutations("quotes", [UPDATE_STEP_METADATA]),
    updateStepContinue(val) {
      // Set whether this step can continue or not
      this[UPDATE_STEP_METADATA]({
        component: this.$options.name,
        canContinue: val
      });
    },
    submit() {
      this.quote.purchaseService = false;
      if (this.allowNextStep && this.$refs.form.validate()) {
        this.nextStep();
      }
    }
  },
  data() {
    return {
      valid: false
    };
  },
  watch: {
    allowNextStep(newVal) {
      // If this step can continue, the the next step should be marked as editable
      this.updateStepContinue(newVal);
    }
  },
  mounted() {
    // Same as watcher
    this.updateStepContinue(this.allowNextStep);
  }
};
</script>
