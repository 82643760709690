import dayjs from "dayjs";
import isTomorrow from "dayjs/plugin/isTomorrow";
import dayOfYear from "dayjs/plugin/dayOfYear";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";
import weekday from "dayjs/plugin/weekday";
import isToday from "dayjs/plugin/isToday";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";

// Add additional plugins to dayjs
[
  isTomorrow,
  dayOfYear,
  isSameOrAfter,
  isBetween,
  isSameOrBefore,
  weekday,
  isToday,
  utc,
  timezone,
  relativeTime
].forEach(plugin => dayjs.extend(plugin));

// Set the default time zone
// TODO: Add (utc, timezone) plugins, uncomment set timezone, add .tz to dayjs export
dayjs.tz.setDefault("America/New_York");

// Export the timezone method
export default dayjs.tz;
export { dayjs };
