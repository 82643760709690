import dayjs from "@/plugins/dayjs";
import { dayjs as vanillaDayJs } from "@/plugins/dayjs";

import cardValidator from "card-validator";

const required = v => !!v || v === 0 || "Field is required";
const email = v => (v && /.+@.+\..+/.test(v)) || !v || "E-mail must be valid";
const phone = v =>
  (v && v.length === 14) || !v || "Please enter a valid phone number";

const dateFuture = v => {
  if (!v) {
    return false;
  }

  let now = dayjs();
  let date = dayjs(v);

  return (
    date.isSameOrAfter(now) || date.isToday() || "Date cannot be in the past."
  );
};

// If current day is a Saturday and current time is after 4PM EST, only allow next available date to be selected to be the upcoming Tuesday
// If current day is a Sunday, only allow next available date to be selected to be the upcoming Tuesday
const validateWeekend = v => {
  let now = dayjs();
  let date = dayjs(v);

  // It's past 4pm on a Saturday or it's a Sunday
  if ((now.hour() >= 16 && now.day() === 6) || now.day() === 0) {
    // Only allow next tuesday
    let nextTuesday = now.add(now.day() === 6 ? 3 : 2, "day");

    if (date.dayOfYear() < nextTuesday.dayOfYear()) {
      return false;
    }
  }

  return dateFuture(v);
};

const dateNoWeekend = v => {
  if (!v) {
    return false;
  }

  if (!validateWeekend(v)) {
    return "Date must be next Tuesday at the earliest.";
  }

  return true;
};

const range = ({ max, min, string, prefix = "" }) => {
  const validateMaxAmount = v => {
    if (v > max) {
      return `${string} cannot be greater than ${prefix}${max}`;
    }
    return true;
  };

  const validateMinAmount = v => {
    if (v <= min) {
      return `${string} should be greater than ${prefix}${min}`;
    }
    return true;
  };

  return [required, validateMaxAmount, validateMinAmount];
};

const creditCard = v => {
  const validator = cardValidator.number(v);

  return validator.isValid || "Please enter a valid credit card number";
};

const cvv = cardNumber => {
  const validator = cardValidator.number(cardNumber);

  return [
    required,
    v => {
      return (
        (validator.isValid &&
          cardValidator.cvv(v, validator.card.code.size).isValid) ||
        "Please enter a valid CVV"
      );
    }
  ];
};

const futureDateByUnit = ({ month, year, unit }) => {
  return [
    required,
    () => {
      if (!month || !year || !unit) {
        return "Please enter a valid date.";
      }

      if (unit === "month") {
        if (month > 12) {
          return "Month must be 12 or less.";
        } else if (month < 1) {
          return "Month must be 1 or greater.";
        }
      }

      if (unit === "year") {
        if (`${year}`.length !== 4 && `${year}`.length !== 2) {
          return "Year must be 2 or 4 digits.";
        }
      }

      let now = vanillaDayJs();
      let date = vanillaDayJs()
        .month(month - 1)
        .year(year && `${year}`.length === 4 ? year : `20${year}`);

      return date.isSameOrAfter(now) || "Date cannot be in the past.";
    }
  ];
};

export default {
  email: [required, email],
  emailNotRequired: [email],
  required: [required],
  phone: [required, phone],
  phoneNotRequired: [phone],
  futureDate: [required, dateFuture],
  futureDateNotRequired: [dateFuture],
  futureDateNoWeekend: [dateFuture, dateNoWeekend],
  validateWeekend,
  requiredMessage: msg => [v => !!v || v === 0 || msg],
  range,
  creditCard: [required, creditCard],
  creditCardCode: cvv,
  futureDateByUnit
};
