import Vue from "vue";
import dayjsTZ, { dayjs } from "@/plugins/dayjs";

Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjsTZ;
    }
  },
  $dayjs: {
    get() {
      return dayjs;
    }
  }
});
